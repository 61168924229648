import { MintType, Status } from 'types/Collection'
import updateCollection from '../updateCollection'

const lazyMintCollection = async ({
  id,
  sellerId,
}: {
  id: string
  sellerId: string
}): Promise<void> => {
  await updateCollection({
    sellerId,
    id,
    payload: {
      status: Status.PUBLISHED,
      mintType: MintType.LAZY,
    },
  })
}

export default lazyMintCollection
