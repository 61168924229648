import { DocumentData, DocumentReference } from 'firebase/firestore'

const parseEntityData = (ref: DocumentReference<DocumentData>) => {
  const parentRef = ref.parent
  const paths = parentRef.path.split(`/`)

  const sellerIndex = paths.indexOf(`users`)
  const sellerId = sellerIndex >= 0 ? paths[sellerIndex + 1] : undefined

  const collectionIndex = paths.indexOf(`collections`)
  const collectionId = collectionIndex ? paths[collectionIndex + 1] : undefined

  if (!sellerId) {
    throw new Error(`SellerId is undefined!`)
  }

  return {
    parentRef,
    sellerId,
    collectionId,
  }
}

export default parseEntityData
