import React from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import { Variants } from '../../NFTPreviewCard/index'

export type Props = {
  title: string
  description: string
  imagePath: string
  imageAlt: string
  detailsContent?: React.ReactNode
  editionsCount: number
  onClick(): void
  variant?: Variants
}
const QuarterWidthNft = ({
  title,
  description,
  imagePath,
  detailsContent,
  editionsCount,
  onClick,
  imageAlt,
}: Props) => {
  return (
    <Card
      elevation={5}
      sx={{
        borderRadius: 3,
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardMedia
          component={'img'}
          src={imagePath}
          alt={imageAlt}
          height={'200'}
        ></CardMedia>
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
          }}
        >
          <CardContent
            sx={{
              display: `flex`,
              flexDirection: `column`,
            }}
          >
            <Typography fontWeight={`700`}>{title}</Typography>
            <Typography variant={`body2`} mb={2}>
              {description}
            </Typography>
            <ul>{detailsContent}</ul>
            <Typography variant={`body2`} mb={2}>
              Editions {editionsCount}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default QuarterWidthNft
