import { getUser } from 'firebaseAdapter/actions'
import User, { Roles } from 'types/User'
import getSeller from '../getSeller'

const getUserInfo = async (id: string): Promise<User> => {
  try {
    const user = await getUser(id)

    switch (user.role) {
      case Roles.ADMIN:
      case Roles.BUYER:
        return user
      case Roles.SELLER:
        return await getSeller(id)
    }
  } catch (error) {
    throw new Error(`Failed to retrieve User with id of ${id}: ${error}`)
  }
}

export default getUserInfo
