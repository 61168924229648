import { addDoc, updateDoc } from 'firebase/firestore'
import { editionRef, sellerCollectionEditionsRef } from 'firebaseAdapter/refs'
import { getDownloadURL, StorageError } from 'firebase/storage'
import { BaseEdition } from 'types/Edition'
import addUpdateEditionMainImage from '../addUpdateEditionMainImage'

const createEdition = async ({
  userId,
  collectionId,
  onCreated,
  onChange,
  onError,
  onComplete,
  file,
  ...edition
}: {
  userId: string
  collectionId: string
  onCreated(nft: BaseEdition): void
  onChange(nft: BaseEdition): void
  onError(error: StorageError): void
  onComplete(): void
  file: File
} & Omit<BaseEdition, 'id'>) => {
  try {
    const { id } = await addDoc(
      sellerCollectionEditionsRef(userId, collectionId),
      edition
    )

    const newEdition: BaseEdition = {
      ...edition,
      id,
    }

    onCreated(newEdition)

    const uploadTask = addUpdateEditionMainImage(id, file)

    const unsubscribe = uploadTask.on(
      `state_changed`,
      ({ bytesTransferred, totalBytes }) => {
        newEdition.uploadProgress = (bytesTransferred / totalBytes) * 100

        onChange(newEdition)
      },
      onError,
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((assetUrl) => {
          updateDoc(editionRef(userId, collectionId, id), {
            assetUrl,
          })
          newEdition.assetUrl = assetUrl
          onChange(newEdition)
          onComplete()
          unsubscribe()
        })
      }
    )
  } catch (error) {
    throw new Error(`Error while attempting to create a new Edition. ${error}`)
  }
}

export default createEdition
