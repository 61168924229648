import { Modes, Steps } from 'feature/CreateNFTsPage'
import StepOne from 'feature/CreateNFTsPage/components/StepOne'
import StepThree from 'feature/CreateNFTsPage/components/StepThree'
import StepTwo from 'feature/CreateNFTsPage/components/StepTwo'
import { useAppLinks } from 'hooks/routes'
import { ReactNode, useMemo } from 'react'
import useCreateNfts from '../useCreateNfts'
import { MintRights } from 'types/User'

export type Props = {
  step: Steps
  mode: Modes
}

export type ReturnPayload = {
  onProceed(): void
  onBack(): void
  onSave(): void
  nextButtonText: string
  backButtonText: string
  saveButtonText: string
  component: ReactNode
  isValid: boolean
  width: string
  title: string
  collectionIdentifier?: string
  isLoading: boolean
}

const useCreateNftsProps = ({ step, mode }: Props): ReturnPayload => {
  const {
    createNFTsStep1,
    createNFTsStep2,
    createNFTsStep3,
    editNFTsStep1,
    editNFTsStep2,
    editNFTsStep3,
    myCollections,
  } = useAppLinks()

  const {
    editions,
    files,
    user,
    collection,
    onSetCollection,
    onFilesChange,
    onUpdateEdition,
    onMint,
    onLazyMint,
    isLoading,
  } = useCreateNfts({
    mode,
  })

  const props = useMemo(() => {
    switch (step) {
      case Steps.STEP1:
        return {
          onProceed:
            mode === Modes.CREATE
              ? createNFTsStep2
              : () => editNFTsStep2(collection ? collection.id : ``),
          onBack: myCollections,
          nextButtonText: `Next`,
          backButtonText: `Cancel`,
          component: (
            <StepOne
              collectionIdentifier={collection?.id}
              onCollectionChange={onSetCollection}
              mode={mode}
              onFileChange={onFilesChange}
            ></StepOne>
          ),
          isValid:
            collection !== undefined &&
            files.every((file) => file.isComplete || file.isError) &&
            files.length !== 0,
          width: `640px`,
          title: `Upload NFTs - Step 1`,
          collectionIdentifier: collection?.id,
        }
      case Steps.STEP2:
        return {
          onProceed:
            mode === Modes.CREATE
              ? createNFTsStep3
              : () => editNFTsStep3(collection ? collection.id : ``),
          onBack:
            mode === Modes.CREATE
              ? createNFTsStep1
              : () => editNFTsStep1(collection ? collection.id : ``),
          nextButtonText: `Next`,
          backButtonText: `Back`,
          component: (
            <StepTwo
              userId={user ? user.id : ``}
              collectionIdentifier={collection ? collection.id : ``}
              title={collection ? collection.name : ``}
              editions={editions ? editions : []}
              updateEdition={onUpdateEdition}
            ></StepTwo>
          ),
          isValid: true,
          width: `100%`,
          title: `NFT Details - Step 2`,
          collectionIdentifier: collection?.id,
        }
      case Steps.STEP3:
        let nextText = `Mint`
        let nextHandler = onMint

        if (user && user.mintRights === MintRights.LAZY) {
          nextText = `Lazy Mint`
          nextHandler = onLazyMint
        }

        return {
          onProceed: nextHandler,
          onBack:
            mode === Modes.CREATE
              ? createNFTsStep2
              : () => editNFTsStep2(collection ? collection.id : ``),
          nextButtonText: nextText,
          backButtonText: `Back`,
          component: (
            <StepThree
              title={collection ? collection.name : ``}
              editions={editions ? editions : []}
            ></StepThree>
          ),
          isValid: true,
          width: `100%`,
          title: `Finalize & Mint - Step 3`,
          collectionIdentifier: collection?.id,
        }
    }
  }, [
    step,
    collection,
    editions,
    onUpdateEdition,
    onMint,
    onLazyMint,
    createNFTsStep1,
    onFilesChange,
    createNFTsStep2,
    createNFTsStep3,
    editNFTsStep1,
    editNFTsStep2,
    editNFTsStep3,
    myCollections,
    onSetCollection,
    mode,
    user,
    files,
  ])

  return {
    saveButtonText: `Save as draft`,
    onSave: myCollections,
    isLoading,
    ...props,
  }
}

export default useCreateNftsProps
