import { Box, IconButton, useMediaQuery } from '@mui/material'
import FacebookIcon from 'assets/icons/FacebookIcon'
import LinkedInIcon from 'assets/icons/LinkedInIcon'
import TwitterIcon from 'assets/icons/TwitterIcon'
import theme from 'assets/theme'
import Logo from 'feature/Logo'
import LinkContainer from './components/LinkContainer'

const Footer = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up(`lg`))

  return (
    <Box
      sx={{
        backgroundColor: `black`,
        display: `grid`,
        gridTemplateColumns: isDesktop ? `3fr 1fr 1fr 1fr` : `1fr`,
        gap: 4,
        p: 4,
      }}
    >
      <Box
        sx={{
          display: `grid`,
          justifyContent: isDesktop ? `left` : `center`,
          alignContent: `flex-start`,
          gap: 3,
        }}
      >
        <Logo />
        <Box
          sx={{
            display: `flex`,
            gap: 2,
            justifyContent: isDesktop ? `left` : `center`,
          }}
        >
          <IconButton
            LinkComponent={`a`}
            href={`https://twitter.com/veritoneinc`}
            target={`_blank`}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            LinkComponent={`a`}
            href={`https://twitter.com/veritoneinc`}
            target={`_blank`}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            LinkComponent={`a`}
            href={`https://www.facebook.com/veritoneinc`}
            target={`_blank`}
          >
            <FacebookIcon />
          </IconButton>
        </Box>
      </Box>
      <LinkContainer
        title={`Solutions`}
        links={[
          {
            to: `https://www.veriverse.com/voice`,
            text: `Veritone Voice`,
          },
          {
            to: `https://www.veriverse.com/veritone-avatar`,
            text: `Veritone Avatar`,
          },
          {
            to: `https://www.veriverse.com/verify`,
            text: `Veritone Verify`,
          },
          {
            to: `https://www.veriverse.com/metaverse-migration-services`,
            text: `Metaverse Migration Services`,
          },
        ]}
      ></LinkContainer>
      <LinkContainer
        title={`Company`}
        links={[
          {
            to: `https://www.veritone.com/about-us`,
            text: `About Us`,
          },
          {
            to: `https://www.veriverse.com/why-veriverse`,
            text: `Ethics of AI`,
          },
          {
            to: `https://www.veriverse.com/request-demo`,
            text: `Contact Us`,
          },
          {
            to: `https://www.veritone.com/`,
            text: `Veritone.com`,
          },
        ]}
      ></LinkContainer>
      <LinkContainer
        title={`Resources`}
        links={[
          {
            to: `https://www.veritone.com/resources?case-study`,
            text: `Case Studies`,
          },
          {
            to: `https://www.veriverse.com/faq`,
            text: `FAQ`,
          },
          {
            to: `https://www.veritone.com/terms`,
            text: `Terms of Service`,
          },
          {
            to: `https://www.veritone.com/privacy`,
            text: `Privacy Policy`,
          },
        ]}
      ></LinkContainer>
    </Box>
  )
}

export default Footer
