import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'
import { updateCollection } from 'firebaseAdapter/actions'
import { Status } from 'types/Collection'
import Collection from 'types/Collection'
import { getCollectionsBySeller } from 'firebaseAdapter/actions'

type Props = {
  open: boolean
  modalTogle: (collectionId: string) => void
  collectionId: string | undefined
  sellerId: string | undefined
  setCollections: (collections: Collection[]) => void
}

export default function AlertDialog({
  open,
  modalTogle,
  collectionId,
  sellerId,
  setCollections,
}: Props) {
  const [text, setText] = React.useState('')
  const [isDisabled, setIsDisabled] = React.useState(true)

  const handleClose = () => {
    modalTogle('')
  }

  const handleDelete = async () => {
    if (collectionId && sellerId) {
      await updateCollection({
        sellerId,
        id: collectionId,
        payload: {
          status: Status.DELETED,
        },
      })
      getCollectionsBySeller(sellerId).then(setCollections)
    } else {
      throw new Error(
        'CollectionId or SellerId is undefined when attempting to delete collection'
      )
    }
    modalTogle('')
  }

  const handleSetText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value)
  }

  React.useEffect(() => {
    if (text.toUpperCase() === 'DELETE') {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [text])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Collection'}</DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              mb: '1rem',
            }}
          >
            You are about to permanently delete this collection. All NFT's that
            haven't been sold yet will be lost.
          </Typography>
          <Typography>
            To continue, type <strong>"DELETE"</strong> in the box to confirm.
          </Typography>
          <TextField variant="outlined" value={text} onChange={handleSetText} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus disabled={isDisabled}>
            Delete Collection
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
