// @ts-nocheck
// install info: https://app.pendo.io/s/5865020918857728/admin/app/-323232?tab=install

export default function initPendo() {
  const pendo = (window.pendo = window.pendo || [])

  if (pendo.initialize) {
    return
  }
  ;(function (apiKey) {
    ;(function (p, e, n, d) {
      let v
      let w
      let x
      let y
      let z
      const o = (p[d] = p[d] || {})
      o._q = []
      v = [`initialize`, `identify`, `updateOptions`, `pageLoad`]
      for (w = 0, x = v.length; w < x; ++w) {
        ;(function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? `unshift` : `push`](
                [m].concat([].slice.call(arguments, 0))
              )
            }
        })(v[w])
      }

      y = e.createElement(n)
      y.async = !0
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`
      z = e.getElementsByTagName(n)[0]
      z.parentNode.insertBefore(y, z)
    })(window, document, `script`, `pendo`)
    window.pendo.initialize({})
  })(`cefc4ec9-0826-4b42-7b3e-9b3538e741d0`)
  return window.pendo
  // }
}
