import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

type Props = {
  isProcessing?: boolean
} & ButtonProps

const ProcessingButton = ({
  isProcessing = false,
  children,
  ...props
}: Props) => {
  const processingIndicator = (
    <Box
      sx={{
        position: `absolute`,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: `grid`,
        justifyItems: `center`,
        alignItems: `center`,
      }}
    >
      <CircularProgress size={20}></CircularProgress>
    </Box>
  )

  const indicator = isProcessing ? processingIndicator : null

  return (
    <Box
      sx={{
        position: `relative`,
      }}
    >
      <Button {...props} disabled={isProcessing || props.disabled}>
        {children}
      </Button>
      {indicator}
    </Box>
  )
}

export default ProcessingButton
