import {
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'

type MyNft = {
  edition: DocumentReference | null
  purchaseDate: string
  quantity: string
}

export const firestoreConverter = {
  toFirestore(nft: PartialWithFieldValue<MyNft>): DocumentData {
    return nft
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): MyNft {
    const res = snapshot.data(options) as MyNft

    return res
  },
}

export default MyNft
