import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Typography, Box } from '@mui/material'

type Props = {
  open: boolean
  anchorEl: null | HTMLElement
  handleClose: () => void
  menuInfo: {
    title: string
    icon: React.ReactElement
    onClick: () => void
  }[]
}

const KebobMenu = ({ open, anchorEl, handleClose, menuInfo }: Props) => {
  const menuItems = menuInfo.map((menuItem, index) => (
    <MenuItem
      key={index}
      onClick={() => {
        handleClose()
        menuItem.onClick()
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {menuItem.icon}
        <Typography sx={{ marginLeft: '0.5rem' }} variant={'body2'}>
          {menuItem.title}
        </Typography>
      </Box>
    </MenuItem>
  ))

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {menuItems}
    </Menu>
  )
}

export default KebobMenu
