import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { checkGatePassword } from 'firebaseAdapter/actions'
import { useEffect, useState } from 'react'
import Cookie from 'js-cookie'
import { isAuthedGatedVar } from 'apollo/cache'

const AuthDialog = () => {
  const [password, setPassword] = useState<string>(``)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (process.env.REACT_APP_APPLICATION_ENV === `local`) {
      isAuthedGatedVar(true)
    }
  }, [])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const isValid = await checkGatePassword(password)

    if (isValid) {
      Cookie.set(`gated`, `true`, { expires: 1 })
      isAuthedGatedVar(true)
    } else {
      setError(true)
    }
  }

  return (
    <Dialog open>
      <DialogContent>
        <Typography variant={`h6`} align={`center`}>
          Veritone NFT Marketplace
        </Typography>
        <Box
          sx={{
            display: `grid`,
            justifyContent: `center`,
            gridTemplateColumns: `3fr 1fr`,
            p: 2,
            pb: 0,
            gap: 2,
          }}
          component={`form`}
          onSubmit={handleSubmit}
        >
          <TextField
            label={`Password`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error}
          ></TextField>
          <Button type={`submit`}>Submit</Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AuthDialog
