import { List } from '@mui/material'
import {
  FileUpload,
  OnDeleteType,
  UploadFuncType,
} from 'uiComponents/UploadInput/types'
import IndicatorLine from '../IndicatorLine'

type Props = {
  uploadFunc?: UploadFuncType
  onDelete?: OnDeleteType
  uploads: FileUpload[]
}

const UploadIndicatorContainer = ({ onDelete, uploads }: Props) => {
  const indicators = uploads
    .sort((a, b) => {
      if (a.isError === b.isError) {
        return 0
      } else if (a.isError) {
        return -1
      } else {
        return 1
      }
    })
    .map((upload, index) => {
      return (
        <IndicatorLine
          key={index}
          upload={upload}
          onDelete={onDelete}
        ></IndicatorLine>
      )
    })

  return <List>{indicators}</List>
}

export default UploadIndicatorContainer
