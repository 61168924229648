import { homepageSliderRef } from 'firebaseAdapter/refs'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import HomepageSlider from 'types/HomepageSlider'

const useGetHomepageSlider = (): HomepageSlider[] => {
  const [data] = useCollectionData(homepageSliderRef())

  if (data !== undefined) {
    return data
  }

  return []
}

export default useGetHomepageSlider
