import getUser from 'firebaseAdapter/actions/getUser'
import getSeller from 'firebaseAdapter/actions/getSeller'
import getSingleCollectionBySeller from 'firebaseAdapter/actions/getSingleCollectionBySeller'

const crumbGenerator = async (obj) => {
  const { page, userId, collectionId, sellerId, editionId, editionName } = obj

  switch (page) {
    case 'collection':
      const userName = await getUser(userId).then((res) => res.name)
      const collectionCrumbs = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: userName,
          path: `/collections/${userId}`,
          disabled: true,
        },
        {
          title: 'Collections',
          path: `/collections/${userId}`,
          disabled: false,
        },
      ]
      return collectionCrumbs

    case 'collectionView':
      const sellerName = await getSeller(sellerId).then((res) => res.name)
      const collectionName = await getSingleCollectionBySeller(
        sellerId,
        collectionId
      ).then((res) => res.name)

      const collectionViewCrumbs = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: sellerName,
          path: `/collections/${sellerId}/view/${collectionName}`,
          disabled: true,
        },
        {
          title: collectionName,
          path: `/collections/${sellerId}/view/${collectionName}`,
          disabled: false,
        },
      ]
      return collectionViewCrumbs

    case 'createCollection':
      const sellerNameCreate = await getSeller(sellerId).then((res) => res.name)

      const collectionCreateCrumbs = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: sellerNameCreate,
          path: `/collections/${sellerId}`,
          disabled: true,
        },
      ]
      return collectionCreateCrumbs

    case 'singleEdition':
      const sellerNameEditionSingle = await getSeller(sellerId).then(
        (res) => res.name
      )
      const singleEditionCrumbs = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: sellerNameEditionSingle,
          path: `/collections/${sellerId}/view/${collectionId}`,
          disabled: false,
        },

        {
          title: `${editionName} - Edition 1`,
          path: `/editions/${sellerId}/${collectionId}/${editionId}`,
          disabled: false,
        },
      ]
      return singleEditionCrumbs

    case 'multiEdition':
      const sellerNameEditionMulti = await getSeller(sellerId).then(
        (res) => res.name
      )
      const multiEditionCrumbs = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: sellerNameEditionMulti,
          path: `/collections/${sellerId}/view/${collectionId}`,
          disabled: false,
        },
        {
          title: `${editionName}`,
          path: `/editions/${sellerId}/${collectionId}/${editionId}`,
          disabled: false,
        },
      ]
      return multiEditionCrumbs
    case 'Upload NFTs - Step 1 - edit':
      const collectionNameStep1Edit = await getSingleCollectionBySeller(
        sellerId,
        collectionId
      ).then((res) => res.name)
      const step1CrumbsEdit = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: collectionNameStep1Edit,
          path: `/collections/${sellerId}/view/${collectionId}`,
          disabled: false,
        },
        {
          title: 'Upload Nfts',
          path: `/nfts/${sellerId}/edit/${collectionId}`,
          disabled: false,
        },
      ]
      return step1CrumbsEdit

    case 'NFT Details - Step 2 - edit':
      const collectionNameStep2Edit = await getSingleCollectionBySeller(
        sellerId,
        collectionId
      ).then((res) => res.name)
      const step2CrumbsEdit = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: collectionNameStep2Edit,
          path: `/collections/${sellerId}/view/${collectionId}`,
          disabled: false,
        },
        {
          title: 'Upload Nfts',
          path: `/nfts/${sellerId}/edit/${collectionId}`,
          disabled: false,
        },
        {
          title: 'NFT Details',
          path: `/nfts/${sellerId}/edit-2/${collectionId}`,
          disabled: false,
        },
      ]
      return step2CrumbsEdit

    case 'Finalize & Mint - Step 3 - edit':
      const collectionNameStep3Edit = await getSingleCollectionBySeller(
        sellerId,
        collectionId
      ).then((res) => res.name)
      const step3CrumbsEdit = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: collectionNameStep3Edit,
          path: `/collections/${sellerId}/view/${collectionId}`,
          disabled: false,
        },
        {
          title: 'Upload Nfts',
          path: `/nfts/${sellerId}/edit/${collectionId}`,
          disabled: false,
        },
        {
          title: 'NFT Details',
          path: `/nfts/${sellerId}/edit-2/${collectionId}`,
          disabled: false,
        },
        {
          title: 'Finalize & Mint',
          path: `/nfts/${sellerId}/edit-3/${collectionId}`,
          disabled: false,
        },
      ]
      return step3CrumbsEdit

    case 'Upload NFTs - Step 1 - create':
      const step1CrumbsCreate = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: 'Create NFTs',
          path: `/nfts/${sellerId}/create`,
          disabled: false,
        },
      ]
      return step1CrumbsCreate

    case 'NFT Details - Step 2 - create':
      const collectionNameStep2Create = await getSingleCollectionBySeller(
        sellerId,
        collectionId
      ).then((res) => res.name)
      const step2CrumbsCreate = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: collectionNameStep2Create,
          path: `/collections/${sellerId}/view/${collectionId}`,
          disabled: false,
        },
        {
          title: 'Upload Nfts',
          path: `/nfts/${sellerId}/create/${collectionId}`,
          disabled: false,
        },
        {
          title: 'NFT Details',
          path: `/nfts/${sellerId}/create-2/${collectionId}`,
          disabled: false,
        },
      ]
      return step2CrumbsCreate

    case 'Finalize & Mint - Step 3 - create':
      const collectionNameStep3Create = await getSingleCollectionBySeller(
        sellerId,
        collectionId
      ).then((res) => res.name)
      const step3CrumbsCreate = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
        {
          title: collectionNameStep3Create,
          path: `/collections/${sellerId}/view/${collectionId}`,
          disabled: false,
        },
        {
          title: 'Upload Nfts',
          path: `/nfts/${sellerId}/create/${collectionId}`,
          disabled: false,
        },
        {
          title: 'NFT Details',
          path: `/nfts/${sellerId}/create-2/${collectionId}`,
          disabled: false,
        },
        {
          title: 'Finalize & Mint',
          path: `/nfts/${sellerId}/create-3/${collectionId}`,
          disabled: false,
        },
      ]
      return step3CrumbsCreate

    default:
      const homeCrumbs = [
        {
          title: 'Home',
          path: '/',
          disabled: false,
        },
      ]
      return homeCrumbs
  }
}

export default crumbGenerator
