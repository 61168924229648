import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { BuyNowButtonRailId, userVar } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'
import {
  getEdition,
  getSingleCollectionsBySeller,
} from 'firebaseAdapter/actions'
import RailInfo from '../RailInfo'
import RailPricing from '../RailPricing'
import Edition from 'types/Edition'

const BuyRail = () => {
  const user = useReactiveVar(userVar)
  const railInfo = useReactiveVar(BuyNowButtonRailId)
  const [edition, setEdition] = useState<Edition>()
  const [collectionName, setCollectionName] = useState<string>('')

  useEffect(() => {
    if (railInfo && user) {
      getEdition({
        sellerId: railInfo.sellerId,
        collectionId: railInfo.collectionId,
        id: railInfo.id,
      }).then(setEdition)
      getSingleCollectionsBySeller(user.id, railInfo.collectionId).then((res) =>
        setCollectionName(res.name)
      )
    }
  }, [railInfo, user])

  if (!edition) {
    return <Typography>Not Available</Typography>
  }

  const { assetUrl, name, supply, description } = edition

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <RailInfo
        collectionName={collectionName}
        assetUrl={assetUrl}
        name={name}
        supply={supply}
        description={description}
      />
      <RailPricing edition={edition}></RailPricing>
    </Box>
  )
}

export default BuyRail
