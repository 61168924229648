import { Box } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { Navigation, A11y, Pagination, Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'
import useGetHomepageSlider from 'hooks/useGetHomepageSlider'
import heroImage from '../../assets/images/heroImage.png'
import { Variations } from '../../types/HomepageSlider'

const FeaturedCollectionsSlider = () => {
  const navigate = useNavigate()
  const width = window.innerWidth

  const data = useGetHomepageSlider()

  const slides = data.map((item, index) => (
    <SwiperSlide key={item.id} virtualIndex={index}>
      <Box
        sx={{
          height: `480px`,
          backgroundColor: item.backgroundColor,
          display: `flex`,
          flexDirection: { xs: 'column', s: 'row', md: 'row', lg: 'row' },
        }}
      >
        {item.variation === Variations.LEFT && width > 700 && (
          <Box
            component={`img`}
            src={item.imageUrl}
            sx={{
              width: { xs: `100%`, md: `50%` },
            }}
          />
        )}
        {(item.variation === Variations.LEFT ||
          item.variation === Variations.RIGHT) &&
          width < 700 && (
            <Box
              component={`img`}
              src={heroImage}
              sx={{
                width: { xs: `100%`, md: `50%` },
              }}
            />
          )}
        <Box
          sx={{
            width: { xs: `100%`, md: `50%`, lg: `50%` },
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `center`,
          }}
        >
          <Typography
            fontSize={{ xs: `2rem`, md: `3rem`, lg: `5rem` }}
            textTransform={`uppercase`}
            fontFamily={`Dosis`}
            color={item.textColor}
          >
            {item?.title}
          </Typography>
          <Typography
            textTransform={`uppercase`}
            color={item.textColor}
            fontSize={{ xs: `1.5rem`, md: `2rem`, lg: `3rem` }}
          >
            {item.subTitle}
          </Typography>
          <Button
            sx={{
              width: `200px`,
            }}
            onClick={() => navigate(item.buttonLink)}
          >
            {item?.buttonText
              ? item.buttonText
              : 'Click here to view collection'}
          </Button>
        </Box>
        {item.variation === Variations.RIGHT && width > 700 && (
          <Box
            component={`img`}
            src={item.imageUrl}
            sx={{
              width: { xs: `100%`, md: `50%`, lg: `50%` },
            }}
          />
        )}
      </Box>
    </SwiperSlide>
  ))

  return (
    <Box p={3}>
      <Typography component={`h2`} fontSize={24} fontWeight={700}>
        Featured Collections
      </Typography>
      <Swiper
        virtual
        modules={[Navigation, A11y, Pagination, Virtual]}
        pagination={{
          clickable: true,
          el: `.pagination-container`,
        }}
      >
        {slides}
      </Swiper>
      <Box
        className={`pagination-container`}
        sx={{
          display: `flex`,
          justifyContent: `center`,
          gap: 1,
          p: 2,
        }}
      ></Box>
    </Box>
  )
}

export default FeaturedCollectionsSlider
