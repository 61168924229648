import { useReactiveVar } from '@apollo/client'
import { userVar } from 'apollo/cache'
import { Modes, UpdateEditionType } from 'feature/CreateNFTsPage'
import {
  getSingleCollectionsBySeller,
  lazyMintCollection,
  updateEdition,
} from 'firebaseAdapter/actions'
import { sellerCollectionEditionsRef } from 'firebaseAdapter/refs'
import { useAppLinks } from 'hooks/routes'
import { useWeb3Context } from 'providers/Web3Provider'
import { useCallback, useEffect, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useParams } from 'react-router-dom'
import Collection from 'types/Collection'
import { FileUpload } from 'uiComponents/UploadInput/types'
import handleAppError from 'utils/handleAppError'
import { mintCollection } from 'utils/web3'

export type Props = {
  mode: Modes
}

const useCreateNfts = ({ mode }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const user = useReactiveVar(userVar)
  const [files, setFiles] = useState<FileUpload[]>([])
  const collectionIdFromUrl = useParams().collectionId
  const [collection, setCollection] = useState<Collection | undefined>(
    undefined
  )
  const [collectionId, setCollectionId] = useState<string | undefined>(
    undefined
  )
  const { provider, signer } = useWeb3Context()
  const { myCollections } = useAppLinks()

  const query = () => {
    if (collectionId !== undefined && user !== undefined) {
      return sellerCollectionEditionsRef(user.id, collectionId)
    }

    return undefined
  }

  const [editions] = useCollectionData(query())

  const handleMint = useCallback(async () => {
    try {
      setIsLoading(true)
      if (!provider || !signer) {
        throw new Error(`Web3 is not initialized!`)
      }

      if (!user) {
        throw new Error(`User must be logged in!`)
      }

      if (!collectionId) {
        throw new Error(`Collection id is undefined!`)
      }

      await mintCollection({
        provider,
        signer,
        sellerId: user.id,
        collectionId,
      })

      myCollections()
    } catch (error) {
      return handleAppError(error)
    } finally {
      setIsLoading(false)
    }
  }, [provider, signer, user, collectionId, setIsLoading, myCollections])

  const handleLazyMint = useCallback(async () => {
    try {
      setIsLoading(true)
      const sellerId = user?.id
      const id = collectionId

      if (!sellerId || !id) {
        return handleAppError(`Collection refs are undefined`)
      }

      await lazyMintCollection({
        id,
        sellerId,
      })

      myCollections()
    } catch (error) {
      return handleAppError(error)
    } finally {
      setIsLoading(false)
    }
  }, [collectionId, user, myCollections])

  const handleFilesChange = (files: FileUpload[]) => {
    setFiles(files)
  }

  const handleSetCollection = useCallback(
    (collection: Collection) => {
      setCollection(collection)
      setCollectionId(collection.id)
    },
    [setCollection, setCollectionId]
  )

  const handleUpdateEdition: UpdateEditionType = useCallback(
    async ({ id, payload }) => {
      if (!collection || !user) {
        throw new Error(
          `Unable to update edition in Firebase. User id and/or Collection id is not defined!`
        )
      }

      await updateEdition({
        userId: user.id,
        collectionId: collection.id,
        id,
        payload,
      })
    },
    [user, collection]
  )

  useEffect(() => {
    setCollectionId(collectionIdFromUrl)
  }, [collectionIdFromUrl])

  useEffect(() => {
    if (collectionId && user) {
      getSingleCollectionsBySeller(user.id, collectionId).then((collection) => {
        setCollection(collection)
      })
    }
  }, [collectionId, user])

  return {
    editions,
    files,
    user,
    collectionId,
    collection,
    onFilesChange: handleFilesChange,
    onMint: handleMint,
    onLazyMint: handleLazyMint,
    onUpdateEdition: handleUpdateEdition,
    onSetCollection: handleSetCollection,
    isLoading,
  }
}

export default useCreateNfts
