import { Box, Button, Divider, Typography } from '@mui/material'
import { getCollectionsBySeller } from 'firebaseAdapter/actions'
import { useAppLinks } from 'hooks/routes'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Collection from 'types/Collection'
import CollectionDetailCard from './components/CollectionDetailCard'
import Modal from './components/Modal'
import BreadCrumbs from 'feature/BreadCrumbs'

const CollectionsPage = () => {
  const [collections, setCollections] = useState<Collection[]>([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [modalCollectionId, setModalCollectionId] = useState('')

  const modalTogle = (collectionId: string) => {
    setOpenDeleteModal(!openDeleteModal)
    setModalCollectionId(collectionId)
  }
  const { createNFTsStep1, createCollection } = useAppLinks()
  const { sellerId } = useParams()

  useEffect(() => {
    if (sellerId !== undefined && !collections.length) {
      getCollectionsBySeller(sellerId).then(setCollections)
    }
  }, [sellerId, collections.length])

  const content = collections
    .sort((a, b) => b.createdAt - a.createdAt)
    .map(
      ({
        id,
        name,
        description,
        bannerImageURL,
        status,
        sellerId,
        collectionId,
      }) => (
        <CollectionDetailCard
          key={id}
          title={name}
          description={description}
          imgURL={bannerImageURL}
          status={status}
          collectionId={id}
          modalTogle={modalTogle}
          openDeleteModal={openDeleteModal}
          sellerId={sellerId}
        ></CollectionDetailCard>
      )
    )

  return (
    <Box>
      <BreadCrumbs userId={sellerId} page={'collection'} />
      <Box
        sx={{
          paddingY: 4,
          paddingX: 6,
        }}
      >
        <Box
          sx={{
            display: `grid`,
            gap: 2,
            gridTemplateColumns: `1fr auto auto`,
            marginBottom: 2,
          }}
        >
          <Typography variant={`h2`}>Manage Collections</Typography>
          <Button onClick={createCollection} variant={`secondary`}>
            Create Collection
          </Button>
          <Button onClick={createNFTsStep1}>Create NFTs</Button>
        </Box>
        <Divider
          sx={{
            marginBottom: 4,
          }}
        />
        <Box
          sx={{
            display: `grid`,
            gridTemplateColumns: `repeat(4, 1fr)`,
            gap: 3,
          }}
        >
          {content}
        </Box>
        <Modal
          open={openDeleteModal}
          modalTogle={modalTogle}
          collectionId={modalCollectionId}
          sellerId={sellerId}
          setCollections={setCollections}
        />
      </Box>
    </Box>
  )
}

export default CollectionsPage
