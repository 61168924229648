import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Edition from 'types/Edition'
import getEdition from '../utils/getEdition'

export enum Modes {
  NONE,
  SINGLE,
  MULTI,
}

const useEditionDetailsPage = () => {
  const [mode, setMode] = useState<Modes>(Modes.NONE)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [edition, setEdition] = useState<Edition | undefined>(undefined)
  const [collectionName, setCollectionName] = useState<string>(``)
  const [nftId, setNftId] = useState<string>(``)
  const { sellerId, collectionId, editionId, tokenId } = useParams()

  useEffect(() => {
    if (sellerId && collectionId && editionId) {
      getEdition(sellerId, collectionId, editionId, tokenId)
        .then(({ edition, collectionName }) => {
          if (edition.supply === 1) {
            setMode(Modes.SINGLE)
          } else if (tokenId) {
            setMode(Modes.SINGLE)
            setNftId(tokenId)
          } else {
            setMode(Modes.MULTI)
          }
          setEdition(edition)
          setCollectionName(collectionName)
        })
        .finally(() => setIsLoading(false))
    }
  }, [sellerId, collectionId, editionId, tokenId])

  return {
    isLoading,
    edition,
    collectionName,
    mode,
    nftId,
  }
}

export default useEditionDetailsPage
