import { Box, Typography } from '@mui/material'
import FullWidthNft from './FullWidth'
import HalfWidthNft from './HalfWidth'
import QuarterWidthNft from './QuarterWidth'

export enum Variants {
  QUARTER,
  HALF,
  FULL_WIDTH,
}

export type Props = {
  title: string
  description: string
  imagePath: string
  imageAlt: string
  details?: string[]
  editionsCount: number
  onCardClick(): void
  variant?: Variants
}

const NFTPreviewCard = ({
  title,
  description,
  imagePath,
  details = [],
  editionsCount,
  onCardClick,
  imageAlt,
  variant = Variants.QUARTER,
}: Props) => {
  const detailsContent = details.map((detail, index) => (
    <Typography variant={`body2`} mb={2} key={index}>
      {detail}
    </Typography>
  ))

  return (
    <Box>
      {variant === Variants.FULL_WIDTH && (
        <FullWidthNft
          title={title}
          description={description}
          imagePath={imagePath}
          detailsContent={detailsContent}
          imageAlt={imageAlt}
          onClick={onCardClick}
        />
      )}
      {variant === Variants.HALF && (
        <HalfWidthNft
          title={title}
          description={description}
          imagePath={imagePath}
          detailsContent={detailsContent}
          onClick={onCardClick}
          imageAlt={imageAlt}
        />
      )}
      {variant === Variants.QUARTER && (
        <QuarterWidthNft
          title={title}
          description={description}
          imagePath={imagePath}
          editionsCount={editionsCount}
          detailsContent={detailsContent}
          onClick={onCardClick}
          imageAlt={imageAlt}
        />
      )}
    </Box>
  )
}

export default NFTPreviewCard
