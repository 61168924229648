import {
  DocumentData,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'

export enum Roles {
  BUYER = `BUYER`,
  SELLER = `SELLER`,
  ADMIN = `ADMIN`,
}

export enum MintRights {
  UNLIMITED = `UNLIMITED`,
  FULL = `FULL`,
  LAZY = `LAZY`,
}

type BaseUser = {
  id: string
  role: Roles
  mintRights: MintRights
}

export type Seller = BaseUser & {
  fullName: string
  email: string
}

export type Buyer = BaseUser & {
  address: string
  nonce: string
}

type User = Seller | Buyer

export const firestoreConverter = {
  toFirestore(user: PartialWithFieldValue<Omit<User, 'id'>>): DocumentData {
    return user
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): User {
    const user = snapshot.data(options) as User
    return {
      ...user,
      id: snapshot.id,
    }
  },
}

export default User
