import { useReactiveVar } from '@apollo/client'
import { Alert, AlertProps, Snackbar } from '@mui/material'
import { appNotificationVar } from 'apollo/cache'

export type AppToastProps = {
  isOpen: boolean
  message: string
  severity: AlertProps['severity']
}

const AppToast = () => {
  const { isOpen, message, severity } = useReactiveVar(appNotificationVar)

  const handleClose = () => {
    appNotificationVar({
      isOpen: false,
      message: ``,
      severity,
    })
  }

  return (
    <Snackbar open={isOpen} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default AppToast
