import { EthersSigner } from 'providers/Web3Provider'
import Edition from 'types/Edition'
import { client } from 'apollo/client'
import { UploadToIpfsDocument } from 'graphql/generated'
import multiTokenContract from './contracts/multiTokenContract'
import { marketplaceContract } from './contracts'
import { updateEdition } from 'firebaseAdapter/actions'

type Params = {
  signer: EthersSigner
  edition: Edition
  multiTokenContractAddress: string
  userAddress: string
}

const mintEdition = async ({
  signer,
  edition,
  multiTokenContractAddress,
  userAddress,
}: Params) => {
  const response = await client.mutate({
    mutation: UploadToIpfsDocument,
    variables: {
      name: edition.name,
      description: edition.description,
      imageUrl: edition.assetUrl,
    },
  })

  if (!response.data) {
    throw new Error(`Could not retrieve ipfs payload!`)
  }

  const link = `ipfs://${response.data?.uploadToIPFS}`

  await multiTokenContract(multiTokenContractAddress, signer).mint(
    userAddress,
    edition.supply.toString(),
    link,
    process.env.REACT_APP_PAYMENT_TOKEN_ADDRESS,
    userAddress
  )

  const contractRes = await multiTokenContract(
    multiTokenContractAddress,
    signer
  ).setApprovalForAll(process.env.REACT_APP_CONTRACT_MARKETPLACE_ADDRESS, true)

  await contractRes.wait()

  await marketplaceContract(signer).listItem(
    multiTokenContractAddress,
    1,
    edition.supply,
    process.env.REACT_APP_PAYMENT_TOKEN_ADDRESS,
    edition.price,
    Math.floor(Date.now() / 1000)
  )

  await updateEdition({
    userId: edition.sellerId,
    collectionId: edition.collectionId ? edition.collectionId : ``,
    id: edition.id,
    payload: {
      contract: multiTokenContractAddress,
      ipfsUri: link,
    },
  })
}

export default mintEdition
