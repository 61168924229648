import { Box } from '@mui/system'
import { NavEntry } from 'hooks/useNavigationSchema'
import { Fragment } from 'react'
import DesktopMenu from '../DesktopMenu'

type Props = {
  menus: NavEntry[]
}

const DesktopNav = ({ menus }: Props) => {
  const content = menus.map((menu, index) => {
    if ('Component' in menu) {
      return <Fragment key={`component-${index}`}>{menu.Component}</Fragment>
    }

    return <DesktopMenu key={menu.id} id={menu.id} menu={menu}></DesktopMenu>
  })

  return (
    <Box
      sx={{
        display: `grid`,
        gap: 2,
        alignItems: `center`,
        gridTemplateColumns: `repeat(${content.length}, auto)`,
      }}
    >
      {content}
    </Box>
  )
}

export default DesktopNav
