import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Box, Button } from '@mui/material'
import { userVar } from 'apollo/cache'
import { createEdition, deleteEdition } from 'firebaseAdapter/actions'
import { useAppLinks } from 'hooks/routes'
import Collection from 'types/Collection'
import UploadInput from 'uiComponents/UploadInput'
import { Modes as PageModes } from 'feature/CreateNFTsPage'
import CollectionSelect from '../CollectionSelect'
import {
  FileUpload,
  Modes,
  OnDeleteType,
  UploadFuncType,
} from 'uiComponents/UploadInput/types'
import { useParams } from 'react-router-dom'
import getSingleCollectionBySeller from 'firebaseAdapter/actions/getSingleCollectionBySeller'
import { useNavigate } from 'react-router-dom'

type Props = {
  collectionIdentifier: string | undefined
  onCollectionChange(collection: Collection): void
  mode: PageModes
  onFileChange(files: FileUpload[]): void
}

const StepOne = ({
  collectionIdentifier,
  onCollectionChange,
  mode,
  onFileChange,
}: Props) => {
  const navigate = useNavigate()
  const { collectionId, sellerId } = useParams()
  const { createCollection } = useAppLinks()
  const user = useReactiveVar(userVar)

  const uploadFile: UploadFuncType = ({
    file,
    onProgress,
    onCreate,
    onComplete,
    onError,
  }) => {
    if (user === undefined) {
      throw new Error(`Cannot create NFT, user is not defined!`)
    }

    if (collectionIdentifier === undefined) {
      throw new Error(`Cannot create NFT, collectionId is not defined!`)
    }

    createEdition({
      userId: user.id,
      collectionId: collectionIdentifier,
      name: ``,
      description: ``,
      assetUrl: ``,
      uploadProgress: 0,
      launchDate: ``,
      dropDate: ``,
      expireDate: ``,
      price: 0,
      supply: 1,
      royalties: [],
      onCreated: (nft) =>
        onCreate &&
        onCreate({
          id: nft.id,
        }),
      onChange: (nft) => onProgress(nft.uploadProgress),
      onError: (storageError) => {
        const error = new Error(storageError.message, {
          cause: storageError.cause,
        })
        onError && onError(error)
      },
      onComplete: () => onComplete && onComplete(),
      file,
      token: null,
      ipfsUri: null,
      contract: null,
    })
  }

  const handleDeleteNft: OnDeleteType = (upload) => {
    if (user === undefined) {
      throw new Error(`Cannot create NFT, user is not defined!`)
    }

    if (collectionIdentifier === undefined) {
      throw new Error(`Cannot create NFT, collectionId is not defined!`)
    }

    deleteEdition({
      id: upload.sideCar?.id,
      userId: user.id,
      collectionId: collectionIdentifier,
    })
  }

  useEffect(() => {
    if (collectionId && sellerId) {
      getSingleCollectionBySeller(sellerId, collectionId).then((res) => {
        if (res) {
          if (res.status === 'PUBLISHED' && mode === PageModes.EDIT) {
            navigate(`/`)
          }
        }
      })
    }
  })

  return (
    <Box>
      <Box
        sx={{
          display: `grid`,
          gridTemplateColumns: `320px auto`,
          gap: 2,
          mb: 4,
        }}
      >
        {mode !== 'edit' && (
          <CollectionSelect onChange={onCollectionChange}></CollectionSelect>
        )}
        {mode !== 'edit' && (
          <Button variant={`secondary`} onClick={createCollection}>
            Add New Collection
          </Button>
        )}
      </Box>
      <UploadInput
        title={`Upload NFTs*`}
        subTitle={``}
        allowedFileTypes={[`image/png`, `image/jpeg`, `image/gif`]}
        onUpload={uploadFile}
        onDelete={handleDeleteNft}
        mode={Modes.MULTIPLE}
        isDisabled={!collectionIdentifier && mode !== PageModes.EDIT}
        onFileChange={onFileChange}
      ></UploadInput>
    </Box>
  )
}

export default StepOne
