import { useReactiveVar } from '@apollo/client'
import {
  authProviderVar,
  isAuthenticatedVar,
  sellerWalletProviderVar,
} from 'apollo/cache'
import { Providers } from 'hooks/useAuthentication'
import ConnectedView from './components/ConnectedView'
import ConnectView from './components/ConnectView'

const WalletRail = () => {
  const isConnected = useReactiveVar(isAuthenticatedVar)
  const provider = useReactiveVar(authProviderVar)
  const sellerProvider = useReactiveVar(sellerWalletProviderVar)
  const isConnectedBuyer = isConnected && provider === Providers.METAMASK
  const isConnectedSeller = isConnected && sellerProvider === Providers.METAMASK

  if (isConnectedBuyer || isConnectedSeller) {
    return <ConnectedView />
  }

  return <ConnectView />
}

export default WalletRail
