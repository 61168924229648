import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material'
import useAppNotification from 'hooks/useAppNotification'
import { useWeb3Context } from 'providers/Web3Provider'
import { useState } from 'react'
import ProcessingButton from 'uiComponents/ProcessingButton'
import { authViaMetamask } from 'utils/web3'
import notYetImplemented from 'utils/notYetImplemented'

type Props = {
  isOpen: boolean
  onClose(): void
  onError?(): void
}

const WalletConnectConfirmation = ({
  onError = notYetImplemented,
  isOpen,
  onClose,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const { provider, signer } = useWeb3Context()
  const { sendNotification } = useAppNotification()

  const handleClick = async () => {
    setIsProcessing(true)
    if (!provider || !signer) {
      throw new Error(`Web3 provider is undefined!`)
    }
    try {
      await authViaMetamask(provider, signer)
    } catch (error) {
      let message = `Could not create new User.`
      if (error instanceof Error) {
        message = error.message
      }
      sendNotification({
        message: message,
        severity: `error`,
      })
    } finally {
      setIsProcessing(false)
      onClose()
    }
  }

  return (
    <Dialog open={isOpen} maxWidth={`xs`}>
      <DialogTitle>Welcome to Veritone NFT</DialogTitle>
      <DialogContent>
        <Typography variant={`body2`}>
          By connecting your wallet and using Veritone NFT, you agree to our{' '}
          <Link href="/" target="_blank">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link href="/" target="_blank">
            Privacy Policy
          </Link>
          .
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: `flex`,
          gap: 2,
        }}
      >
        <Button onClick={onClose} variant={`text`}>
          Cancel
        </Button>
        <ProcessingButton onClick={handleClick} isProcessing={isProcessing}>
          Accept and Sign
        </ProcessingButton>
      </DialogActions>
    </Dialog>
  )
}

export default WalletConnectConfirmation
