import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth, GoogleAuthProvider } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const app = initializeApp(firebaseConfig)

const db = getFirestore(app)

const auth = getAuth(app)

const storage = getStorage(app)

const functions = getFunctions(app)

const googleProvider = new GoogleAuthProvider()

const aiwareLogin = httpsCallable(functions, `aiwareLogin`)
const web3Login = httpsCallable(functions, `web3Login`)
const getNonce = httpsCallable(functions, `getNonce`)
const verifyNonce = httpsCallable(functions, `verifyNonce`)

if (process.env.REACT_APP_APPLICATION_ENV === `local`) {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectStorageEmulator(storage, 'localhost', 9199)
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export {
  app,
  auth,
  db,
  storage,
  aiwareLogin,
  web3Login,
  getNonce,
  verifyNonce,
  googleProvider,
}
