import { updateDoc } from 'firebase/firestore'
import { editionRef } from 'firebaseAdapter/refs'
import Edition from 'types/Edition'

const updateEdition = async ({
  userId,
  collectionId,
  id,
  payload,
}: {
  userId: string
  collectionId: string
  id: string
  payload: Partial<Edition>
}) => {
  try {
    await updateDoc(editionRef(userId, collectionId, id), payload)
  } catch (error) {
    throw new Error(`Error while attempting to update a new Edition. ${error}`)
  }
}

export default updateEdition
