import { useEffect, useState } from 'react'
import crumbGenerator from 'utils/crumbGenerator/index'
import { Breadcrumbs, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

type Props = {
  page: string
  userId?: string
  collectionId?: string
  sellerId?: string
  editionId?: string
  editionName?: string
}

type Crumbs = {
  title: string
  path: string
  disabled: boolean
}

const BreadCrumbs = ({
  userId,
  page,
  collectionId,
  sellerId,
  editionId,
  editionName,
}: Props) => {
  const [crumbsArray, setCrumbsArray] = useState<Crumbs[]>()
  useEffect(() => {
    crumbGenerator({
      page,
      userId,
      collectionId,
      sellerId,
      editionId,
      editionName,
    }).then(setCrumbsArray)
  }, [page, userId, collectionId, sellerId, editionId, editionName])

  const crumbs = crumbsArray?.map((x, index) => {
    if (x.path) {
      if (
        (index === crumbsArray.length - 1 && x.title !== 'Home') ||
        x.disabled === true
      ) {
        return <Typography key={index}>{x.title}</Typography>
      } else {
        return (
          <Link to={x.path} style={{ textDecoration: 'none' }} key={index}>
            <Typography
              sx={{
                color: '#6098D1',
                fontWeight: 'bold',
              }}
            >
              {x.title}
            </Typography>
          </Link>
        )
      }
    }
    return null
  })

  return <Breadcrumbs aria-label="breadcrumb">{crumbs}</Breadcrumbs>
}

export default BreadCrumbs
