import { Box, Button, Divider, Typography } from '@mui/material'
import { createCollection } from 'firebaseAdapter/actions'
import { useAppLinks } from 'hooks/routes'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import TextInput from 'uiComponents/TextInput'
import UploadInput from 'uiComponents/UploadInput'
import { FileUpload } from 'uiComponents/UploadInput/types'
import { MintType, Status } from 'types/Collection'
import BreadCrumbs from 'feature/BreadCrumbs'

type FormFields = {
  name: string
  description: string
  fileUpload: File[] | undefined
}

const CreateCollectionPage = () => {
  const { myCollections } = useAppLinks()
  const { sellerId } = useParams()

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm<FormFields>({
    mode: `onChange`,
  })
  const fileList = watch(`fileUpload`)
  const isFormValid = isValid && fileList !== undefined && fileList.length > 0

  const handleFormSubmit = async ({
    name,
    description,
    fileUpload,
  }: FormFields) => {
    try {
      if (sellerId === undefined) {
        throw new Error(`sellerId param is not defined!`)
      }

      if (fileUpload === undefined) {
        throw new Error(`There are no files in the file list.`)
      }

      const bannerFile = fileUpload[0]

      await createCollection({
        sellerId,
        name,
        description,
        bannerFile,
        status: Status.DRAFT,
        createdAt: new Date().getTime(),
        mintType: MintType.LAZY,
        contract: null,
      })

      myCollections()
    } catch (error) {
      throw new Error(`Error while submitting form! ${error}`)
    }
  }

  const handleFileChange = useCallback(
    (files: FileUpload[]) => {
      setValue(
        `fileUpload`,
        files.map((file) => file.file)
      )
    },
    [setValue]
  )

  return (
    <Box>
      <BreadCrumbs page={'createCollection'} sellerId={sellerId} />
      <Box
        sx={{
          maxWidth: `640px`,
          margin: `0 auto`,
          paddingY: 5,
          paddingX: 2,
        }}
      >
        <Typography variant={`h2`} mb={2}>
          Create A Collection
        </Typography>
        <Divider
          sx={{
            marginBottom: 3,
          }}
        ></Divider>
        <Box component={`form`} onSubmit={handleSubmit(handleFormSubmit)}>
          <TextInput
            id={`collection-name-input`}
            label={`Collection Name*`}
            {...register(`name`, {
              required: `Please enter a Collection Name`,
            })}
            fullWidth
            sx={{
              maxWidth: `400px`,
            }}
            error={Boolean(errors.name)}
            helperText={errors.name ? errors.name?.message : ` `}
          ></TextInput>
          <TextInput
            id={`collection-description-input`}
            label={`Description*`}
            {...register(`description`, {
              required: `Please enter a Collection Description`,
            })}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            sx={{
              maxWidth: `400px`,
            }}
            error={Boolean(errors.description)}
            helperText={errors.description ? errors.description?.message : ` `}
          ></TextInput>
          <UploadInput
            title={`Upload Collection Banner Image*`}
            subTitle={`This banner image will be used to market your collection. Image recommended size 1400x900`}
            allowedFileTypes={[`image/png`, `image/jpeg`]}
            onFileChange={handleFileChange}
            inputProps={{
              ...register(`fileUpload`),
            }}
          ></UploadInput>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `flex-end`,
              gap: 2,
            }}
          >
            <Button variant={`secondary`}>Cancel</Button>
            <Button type={`submit`} disabled={!isFormValid}>
              Create Collection
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateCollectionPage
