import { DeleteOutline } from '@mui/icons-material'
import { Divider, IconButton, InputAdornment } from '@mui/material'
import { Box } from '@mui/system'
import { UpdateEditionType } from 'feature/CreateNFTsPage'
import { deleteEdition } from 'firebaseAdapter/actions'
import Edition, { BaseEdition } from 'types/Edition'
import TextInput from 'uiComponents/TextInput'
import MuiDatePicker from 'feature/MuiDatePicker'
import UsdcCoinIcon from 'assets/icons/UsdcCoinIcon'

type Props = {
  userId: string
  collectionId: string
  updateEdition: UpdateEditionType
  edition: Edition
}

const NftEditEntry = ({
  userId,
  collectionId,
  updateEdition,
  edition,
}: Props) => {
  const {
    assetUrl,
    id,
    name,
    description,
    supply,
    price,
    launchDate,
    dropDate,
    expireDate,
  } = edition

  const handleUpdate = (payload: Partial<BaseEdition>) => {
    updateEdition({
      id,
      payload,
    })
  }

  const handleDelete = () => {
    deleteEdition({
      id,
      userId,
      collectionId,
    })
  }

  return (
    <Box>
      <Divider />
      <Box
        sx={{
          display: `grid`,
          gridTemplateColumns: `2fr 5fr 3fr`,
          gap: 3,
          p: 2,
        }}
      >
        <img src={assetUrl} alt={`preview`} />
        <Box>
          <TextInput
            id={`name-input-${id}`}
            label={`Name`}
            defaultValue={name}
            onChange={(e) =>
              handleUpdate({
                name: e.target.value,
              })
            }
          ></TextInput>
          <TextInput
            id={`desc-input-${id}`}
            label={`Description`}
            value={description}
            onChange={(e) =>
              handleUpdate({
                description: e.target.value,
              })
            }
          ></TextInput>
        </Box>
        <Box
          display={`grid`}
          justifyContent={`end`}
          alignContent={`flex-start`}
        >
          <IconButton onClick={handleDelete} size={`small`}>
            <DeleteOutline fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          pl: '10px',
          pr: '10px',
          display: `flex`,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MuiDatePicker
          label={'Launch Date'}
          value={launchDate}
          onChange={(payload: string | null) =>
            handleUpdate({
              launchDate: `${payload}`,
            })
          }
        />
        <MuiDatePicker
          label={'Drop (Live) Date*'}
          value={dropDate}
          onChange={(payload: string | null) =>
            handleUpdate({
              dropDate: `${payload}`,
            })
          }
        />
        <MuiDatePicker
          label={'Expiration Date'}
          value={expireDate}
          onChange={(payload: string | null) =>
            handleUpdate({
              expireDate: `${payload}`,
            })
          }
        />
        <TextInput
          id={`launch-input-${id}`}
          label={`Editions*`}
          value={supply}
          type={`number`}
          onChange={(e) =>
            handleUpdate({
              supply: parseInt(e.target.value),
            })
          }
        ></TextInput>
        <TextInput
          id={`money-input-${id}`}
          label={`Price - USDC*`}
          value={price}
          type={`number`}
          onChange={(e) =>
            handleUpdate({
              price: parseInt(e.target.value),
            })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <UsdcCoinIcon />
              </InputAdornment>
            ),
          }}
        ></TextInput>
      </Box>
    </Box>
  )
}

export default NftEditEntry
