import { getDoc } from 'firebase/firestore'
import { collectionRef, editionRef } from 'firebaseAdapter/refs'
import { sendNotification } from 'hooks/useAppNotification'
import Edition from 'types/Edition'

type ReturnPayload = {
  edition: Edition
  collectionName: string
}

const getEdition = async (
  sellerId: string,
  collectionId: string,
  editionId: string,
  tokenId?: string
): Promise<ReturnPayload> => {
  try {
    const snapshot = await getDoc(editionRef(sellerId, collectionId, editionId))
    const collectionSnapshot = await getDoc(
      collectionRef(sellerId, collectionId)
    )

    const edition = snapshot.data()
    const collection = collectionSnapshot.data()

    if (
      !snapshot.exists() ||
      edition === undefined ||
      collection === undefined
    ) {
      const message = `Could not find Edition in the DB.`
      sendNotification({
        message,
        severity: `error`,
      })
      throw new Error(message)
    }

    return {
      edition,
      collectionName: collection.name,
    }
  } catch (error) {
    const message = `Unexpected error has occured while attempting to retrieve Edition.`
    sendNotification({
      message,
      severity: `error`,
    })
    throw new Error(message)
  }
}

export default getEdition
