import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import buyNft from 'utils/web3/buyNft'
import { useWeb3Context } from 'providers/Web3Provider'
import Edition from 'types/Edition'
import handleAppError from 'utils/handleAppError'
import ProcessingButton from 'uiComponents/ProcessingButton'
import { isRailOpenVar, railModeVar } from 'apollo/cache'
import { RailModes } from '..'

type Props = {
  edition: Edition
}

const PricingButtons = ({ edition }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const { signer } = useWeb3Context()

  const handlePayClick = async () => {
    setIsLoading(true)
    try {
      if (!signer) {
        throw new Error(`Signer is undefined!`)
      }

      if (edition.supply > 1) {
        throw new Error(
          `Buying editions with more than one nft is not currently supported.`
        )
      }

      await buyNft({
        signer,
        edition,
      })
      isRailOpenVar(false)
      railModeVar(RailModes.NONE)
    } catch (error) {
      return handleAppError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box>
      <Box>
        <FormControlLabel
          sx={{
            fontSize: '11px',
          }}
          control={<Checkbox />}
          label={
            <Typography
              sx={{
                fontSize: '11px',
              }}
            >
              By checking this box, I agree to Veritone's Terms of Service
            </Typography>
          }
          onChange={(e, checked) => setIsValid(checked)}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {/* <Button
          variant="outlined"
          sx={{
            mr: 2,
          }}
        >
          Add Funds
        </Button> */}
        <ProcessingButton
          isProcessing={isLoading}
          disabled={!isValid}
          onClick={handlePayClick}
        >
          Pay
        </ProcessingButton>
      </Box>
    </Box>
  )
}

export default PricingButtons
