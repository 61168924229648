import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import UsdcCoinIcon from 'assets/icons/UsdcCoinIcon'

type Props = {
  price: string
  secondaryPrice: string
}

const PriceBox = ({ price }: Props) => {
  return (
    <Box
      sx={{
        borderRadius: `4px`,
        paddingX: 1.5,
        paddingY: 1,
        backgroundColor: `#2A323C`,
        color: `#FFFFFF`,
      }}
    >
      <Typography
        fontSize={`12px`}
        lineHeight={`24px`}
        fontWeight={700}
        textTransform={`uppercase`}
        textAlign={`center`}
        mb={1}
      >
        Current Price
      </Typography>
      <Box
        sx={{
          display: `grid`,
          gap: `7px`,
          alignItems: `center`,
          gridTemplateColumns: `auto auto`,
          mb: `7px`,
        }}
      >
        <UsdcCoinIcon />
        <Typography
          fontSize={`28px`}
          lineHeight={`34px`}
          fontWeight={700}
          textTransform={`uppercase`}
          textAlign={`center`}
        >
          {price}
        </Typography>
      </Box>
      {/* <Typography
        fontSize={`12px`}
        lineHeight={`18px`}
        textTransform={`uppercase`}
        textAlign={`center`}
      >
        {price} usd
      </Typography> */}
    </Box>
  )
}

export default PriceBox
