import { useMediaQuery } from '@mui/material'
import theme from 'assets/theme'
import MobileNav from '../MobileNav'
import DesktopNav from '../DesktopNav'
import { useReactiveVar } from '@apollo/client'
import { isAuthenticatedVar, userVar } from 'apollo/cache'
import useNavigationSchema from 'hooks/useNavigationSchema'

const NavigationMenu = () => {
  const schema = useNavigationSchema()
  const isDesktop = useMediaQuery(theme.breakpoints.up(`lg`))
  const isAuthenticated = useReactiveVar(isAuthenticatedVar)
  const user = useReactiveVar(userVar)

  const menus = schema
    .filter((menu) => {
      if ('showIfAuthenticated' in menu) {
        return menu.showIfAuthenticated === isAuthenticated
      }

      return true
    })
    .filter((menu) => {
      if (menu.userRoles !== undefined && user !== undefined) {
        return menu.userRoles.includes(user.role)
      }

      return true
    })
    .map((menu) => {
      if ('items' in menu) {
        menu.items = menu?.items?.filter((item) => {
          if (item.userRoles !== undefined && user !== undefined) {
            return item.userRoles.includes(user.role)
          }

          return true
        })
      }

      return menu
    })

  if (isDesktop) {
    return <DesktopNav menus={menus}></DesktopNav>
  }

  return <MobileNav menus={menus}></MobileNav>
}

export default NavigationMenu
