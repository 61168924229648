import { Box } from '@mui/material'
import NFTPreviewCard from '../../NFTPreviewCard'
import { Variants } from '../../NFTPreviewCard'
import { Typography } from '@mui/material'
import Edition from '../../../types/Edition'
import Collection from 'types/Collection'
import { useAppLinks } from 'hooks/routes'

export type Props = {
  collection: Collection | undefined
  featuredNfts: {
    primary1: Edition | undefined
    primary2: Edition | undefined
    primary3: Edition | undefined
  }
}

const FeaturedNFTs = ({ featuredNfts, collection }: Props) => {
  const { primary1, primary2, primary3 } = featuredNfts
  const { editionDetailsPage } = useAppLinks()

  const handlePrimary1 = () => {
    if (!primary1) return
    if (primary1?.collectionId === undefined) return
    return editionDetailsPage(
      primary1.sellerId,
      primary1.collectionId,
      primary1.id
    )
  }
  const handlePrimary2 = () => {
    if (!primary2) return
    if (primary2?.collectionId === undefined) return
    return editionDetailsPage(
      primary2.sellerId,
      primary2.collectionId,
      primary2.id
    )
  }
  const handlePrimary3 = () => {
    if (!primary3) return
    if (primary3?.collectionId === undefined) return
    return editionDetailsPage(
      primary3.sellerId,
      primary3.collectionId,
      primary3.id
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 4,
      }}
    >
      <Box
        sx={{
          mb: 2,
          mt: 2,
        }}
      >
        {primary1 !== undefined &&
          primary2 !== undefined &&
          primary3 !== undefined &&
          collection !== undefined && (
            <>
              <Typography variant={`h6`} fontWeight={'700'}>
                {collection?.name ? collection.name : ''}
              </Typography>
              <Typography variant={`h6`}>
                {collection?.description ? collection.description : ''}
              </Typography>
            </>
          )}
      </Box>
      <Box>
        {primary1 && (
          <NFTPreviewCard
            title={primary1.name}
            description={primary1.description}
            imagePath={primary1.assetUrl}
            imageAlt={primary1.assetUrl}
            editionsCount={primary1.supply}
            onCardClick={() => {
              handlePrimary1()
            }}
            variant={Variants.FULL_WIDTH}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {primary2 && (
          <NFTPreviewCard
            title={primary2.name}
            description={primary2.description}
            imagePath={primary2.assetUrl}
            imageAlt={primary2.assetUrl}
            editionsCount={primary2.supply}
            onCardClick={() => {
              handlePrimary2()
            }}
            variant={Variants.HALF}
          />
        )}
        {primary3 && (
          <NFTPreviewCard
            title={primary3.name}
            description={primary3.description}
            imagePath={primary3.assetUrl}
            imageAlt={primary3.assetUrl}
            editionsCount={primary3.supply}
            onCardClick={() => {
              handlePrimary3()
            }}
            variant={Variants.HALF}
          />
        )}
      </Box>
    </Box>
  )
}

export default FeaturedNFTs
