import { getDoc, getDocs } from 'firebase/firestore'
import {
  collectionRef,
  sellerCollectionEditionsRef,
} from 'firebaseAdapter/refs'
import Collection from 'types/Collection'
import Edition from 'types/Edition'

const getEditions = async ({
  sellerId,
  collectionId,
}: {
  sellerId: string
  collectionId: string
}): Promise<{
  collection: Collection
  editions: Edition[]
}> => {
  const collectionSnapshot = await getDoc(collectionRef(sellerId, collectionId))

  if (!collectionSnapshot.exists()) {
    throw new Error(`Could not find collection!`)
  }
  const collection = collectionSnapshot.data()
  const editionsSnapshot = await getDocs(
    sellerCollectionEditionsRef(sellerId, collectionId)
  )
  const editions = editionsSnapshot.docs.map((d) => d.data())
  return {
    editions,
    collection,
  }
}

export default getEditions
