import { onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import { useEffect } from 'react'
import { aiwareLogin, auth } from 'firebaseAdapter/utils'
import Cookies from 'js-cookie'
import { useReactiveVar } from '@apollo/client'
import {
  authProviderVar,
  isAuthedGatedVar,
  isAuthenticatedVar,
  sellerWalletProviderVar,
  userVar,
} from 'apollo/cache'
import { getUserInfo } from 'firebaseAdapter/actions'
import UrlParams from 'types/UrlParams'
import generateOAuthUrl from 'utils/generateOAuthUrl'
import getAccessTokenFromUrl from 'utils/getAccessTokenFromUrl'
import User, { Buyer, Roles } from 'types/User'
import { useWeb3Context } from 'providers/Web3Provider'

export enum Providers {
  NONE = `none`,
  AIWARE = `aiware`,
  METAMASK = `metamask`,
}

const loginUser = ({ user, provider }: { user: User; provider: Providers }) => {
  isAuthenticatedVar(true)
  userVar(user)
  authProviderVar(provider)
}

export const logoutUser = () => {
  auth.signOut().then(() => {
    isAuthenticatedVar(false)
    userVar(undefined)
    authProviderVar(Providers.NONE)
    sellerWalletProviderVar(Providers.NONE)
  })
}

const useAuthentication = (): {
  isAuthedGated: boolean
  isSignedIn: boolean
} => {
  const { provider, signer } = useWeb3Context()
  const isAuthedGated = useReactiveVar(isAuthedGatedVar)
  const isAuthenticated = useReactiveVar(isAuthenticatedVar)

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser !== null) {
        getUserInfo(firebaseUser.uid).then(async (user) => {
          switch (user.role) {
            case Roles.BUYER:
              if (signer) {
                const walletAddress = await signer.getAddress()
                const buyer = user as Buyer
                if (buyer.address === walletAddress) {
                  loginUser({
                    user,
                    provider: Providers.METAMASK,
                  })
                } else {
                  logoutUser()
                }
              }
              break
            case Roles.SELLER:
              loginUser({
                user,
                provider: Providers.AIWARE,
              })
              break
          }
        })
      } else {
        userVar(undefined)
        const params = new URLSearchParams(window.location.search)
        const provider = params.get(UrlParams.PROVIDER)
        const accessToken = getAccessTokenFromUrl(window.location)

        if (provider === Providers.AIWARE) {
          if (accessToken) {
            aiwareLogin({
              token: accessToken,
            }).then((res) => {
              const firebaseToken = res.data as string
              signInWithCustomToken(auth, firebaseToken).then(() => {
                window.history.pushState({}, ``, `/`)
              })
            })
          } else {
            window.location.replace(generateOAuthUrl())
          }
        }
      }
    })
  }, [signer])

  useEffect(() => {
    if (provider) {
      provider.on('accountsChanged', (payload) => {
        const accounts: string[] = payload as string[]
        const userAddress = accounts[0]
        if (!userAddress) {
          logoutUser()
        }
      })
    }
  }, [provider])

  useEffect(() => {
    if (Cookies.get(`gated`) === `true`) {
      isAuthedGatedVar(true)
    }
  }, [])

  return {
    isAuthedGated,
    isSignedIn: isAuthenticated,
  }
}

export default useAuthentication
