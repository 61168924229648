import { Link, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import theme from 'assets/theme'

type Props = {
  title: string
  links: {
    to: string
    text: string
  }[]
}

const LinkContainer = ({ title, links }: Props) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up(`lg`))

  const content = links.map((link, index) => (
    <Link
      key={index}
      component={`a`}
      href={link.to}
      sx={{
        color: `white`,
        textDecoration: `none`,
        fontSize: `18px`,
        fontWeight: `700`,
        ':hover': {
          color: `#f50057`,
          transition: `.5s ease-in-out`,
        },
      }}
    >
      {link.text}
    </Link>
  ))

  return (
    <Box
      sx={{
        display: `grid`,
        gap: 2,
        textAlign: isDesktop ? `left` : `center`,
        justifyContent: isDesktop ? `flex-start` : `center`,
        alignContent: `flex-start`,
      }}
    >
      <Typography color={`white`}>{title}</Typography>
      {content}
    </Box>
  )
}

export default LinkContainer
