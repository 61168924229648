import { MouseEvent, useState } from 'react'
import { Button, IconButton, Menu, MenuItem } from '@mui/material'
import { Box } from '@mui/system'
import { NavMenu } from 'hooks/useNavigationSchema'

type Props = {
  id: string
  menu: NavMenu
}

const DesktopMenu = ({
  id,
  menu: { items, Icon, title, type, onClick },
}: Props) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElNav(null)
  }

  const headerButton = () => {
    if (Icon) {
      return (
        <IconButton
          size="large"
          aria-controls={id}
          aria-haspopup="true"
          onClick={type === 'button' ? onClick : handleOpen}
          color="inherit"
        >
          {Icon}
        </IconButton>
      )
    }

    return (
      <Button
        variant={`text`}
        onClick={handleOpen}
        sx={{
          color: `white`,
        }}
      >
        {title}
      </Button>
    )
  }

  const menuItems = items?.map((item, index) => {
    return (
      <MenuItem
        key={index}
        sx={{
          color: `white`,
        }}
        onClick={item.onClick}
      >
        {item.text}
      </MenuItem>
    )
  })

  return (
    <Box>
      {headerButton()}
      <Menu
        id={id}
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            backgroundColor: `black`,
          },
        }}
        onClick={handleClose}
      >
        {menuItems}
      </Menu>
    </Box>
  )
}

export default DesktopMenu
