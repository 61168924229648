import { ethers } from 'ethers'
import { EthersSigner } from 'providers/Web3Provider'
// https://github.com/veritone/nft-marketplace-backend/blob/develop/pkg/blockchain/abi/Marketplace-abi.json

type Contract = {
  listItem(
    nftAddress: string,
    tokenId: number,
    quantity: number,
    payToken: string,
    pricePerItem: number,
    startingTime: number
  ): Promise<any>
  buyItem(
    nftAddress: string,
    tokenId: number,
    payToken: string,
    ownerAddress: string,
    overrides?: ethers.Overrides
  ): Promise<any>
} & ethers.Contract

const marketplaceContract = (signer: EthersSigner) => {
  const abi: string[] = [
    'function listItem(address, uint256, uint256, address, uint256, uint256) nonpayable returns ()',
    'function buyItem(address, uint256, address, address) nonpayable returns ()',
  ]

  return new ethers.Contract(
    process.env.REACT_APP_CONTRACT_MARKETPLACE_ADDRESS,
    abi,
    signer
  ) as Contract
}

export default marketplaceContract
