import { getEditions, updateCollection } from 'firebaseAdapter/actions'
import { EthersProvider, EthersSigner } from 'providers/Web3Provider'
import { MintType, Status } from 'types/Collection'
import { multiTokenNFTFactoryContract } from './contracts'
import mintEdition from './mintEdition'

type Params = {
  signer: EthersSigner
  provider: EthersProvider
  sellerId: string
  collectionId: string
}

const mintCollection = async ({
  provider,
  signer,
  sellerId,
  collectionId,
}: Params) => {
  if (!provider || !signer) {
    throw new Error(`Web3 not initialized!`)
  }
  const userAddress = await signer.getAddress()

  const { collection, editions } = await getEditions({ sellerId, collectionId })

  const contractAddress = await multiTokenNFTFactoryContract(
    signer
  ).createNFTContract(
    collection.name,
    `VERI`,
    collection.bannerImageURL,
    process.env.REACT_APP_PAYMENT_TOKEN_ADDRESS
  )

  const { events } = await contractAddress.wait()

  //@ts-ignore
  const event = events.find((event) => event.event === 'ContractCreated1155')
  const multiTokenContractAddress = event.args[1]

  const mintPromises = editions.map((edition) =>
    mintEdition({
      signer,
      edition,
      multiTokenContractAddress,
      userAddress,
    })
  )

  await Promise.all(mintPromises)

  await updateCollection({
    sellerId,
    id: collectionId,
    payload: {
      status: Status.PUBLISHED,
      mintType: MintType.FULL,
      contract: multiTokenContractAddress,
    },
  })

  //Token needs isMinted = true
}

export default mintCollection
