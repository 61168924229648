import createMyNft from 'firebaseAdapter/actions/createMyNft'
import getToken from 'firebaseAdapter/actions/getToken'
import { EthersSigner } from 'providers/Web3Provider'
import Edition from 'types/Edition'
import { erc20contract, marketplaceContract } from './contracts'

type Params = {
  signer: EthersSigner
  edition: Edition
}

const buyNft = async ({ signer, edition }: Params) => {
  const { contract, collectionId } = edition

  if (!contract) {
    throw new Error(`Contract is null!`)
  }

  if (!collectionId) {
    throw new Error(`CollectionId is undefined!`)
  }

  if (!edition.token) {
    throw new Error(`Token ref is undefined!`)
  }

  const token = await getToken({
    ref: edition.token,
  })

  if (!token) {
    throw new Error(`Could not find the token!`)
  }

  const userAddress = await signer.getAddress()

  const allowance = await erc20contract(
    process.env.REACT_APP_PAYMENT_TOKEN_ADDRESS,
    signer
  ).allowance(userAddress, process.env.REACT_APP_CONTRACT_MARKETPLACE_ADDRESS)

  const spendingAmount = allowance.toNumber()

  if (edition.price > spendingAmount) {
    const isApprovedRes = await erc20contract(
      process.env.REACT_APP_PAYMENT_TOKEN_ADDRESS,
      signer
    ).approve(process.env.REACT_APP_CONTRACT_MARKETPLACE_ADDRESS, edition.price)

    await isApprovedRes.wait()
  }

  await marketplaceContract(signer).buyItem(
    contract,
    parseInt(token.tokenId),
    process.env.REACT_APP_PAYMENT_TOKEN_ADDRESS,
    token.multiNftOwner
  )

  await createMyNft({
    purchaseDate: Date.now().toString(),
    edition,
  })
}

export default buyNft
