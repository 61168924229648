import { useReactiveVar } from '@apollo/client'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { userVar } from 'apollo/cache'
import { getNonMintedCollectionsBySeller } from 'firebaseAdapter/actions'
import { useEffect, useState } from 'react'
import Collection from 'types/Collection'

type Props = {
  onChange(collection: Collection): void
}

const CollectionSelect = ({ onChange }: Props) => {
  const [selection, setSelection] = useState<string>(``)
  const [collections, setCollections] = useState<Collection[]>([])
  const user = useReactiveVar(userVar)

  const handleChange = (event: SelectChangeEvent) => {
    const collectionId = event.target.value
    const collection = collections.find((el) => el.id === collectionId)
    setSelection(collectionId)

    if (collection === undefined) {
      throw new Error(`Could not find collection!`)
    }

    onChange(collection)
  }

  useEffect(() => {
    if (user !== undefined) {
      getNonMintedCollectionsBySeller(user.id).then(setCollections)
    }
  }, [user])

  const items = collections.map((collection) => (
    <MenuItem key={collection.id} value={collection.id}>
      {collection.name}
    </MenuItem>
  ))

  return (
    <Select
      value={selection}
      displayEmpty
      size={`small`}
      onChange={handleChange}
    >
      <MenuItem value="">Choose Collection*</MenuItem>
      {items}
    </Select>
  )
}

export default CollectionSelect
