import { Typography, TypographyProps } from '@mui/material'

const DetailText = (props: TypographyProps) => {
  return (
    <Typography fontWeight={600} {...props}>
      {props.children}
    </Typography>
  )
}

export default DetailText
