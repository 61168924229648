import { Box, Button, Typography } from '@mui/material'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import UploadIcon from '@mui/icons-material/CloudUploadOutlined'
import theme from 'assets/theme'
import { useEffect, useMemo, useReducer } from 'react'
import UploadIndicatorContainer from './components/UploadIndicatorContainer'
import { FileUpload, Modes, OnDeleteType, UploadFuncType } from './types'
import reducer, { Actions, initialState } from './reducer'
import notYetImplemented from 'utils/notYetImplemented'

type Props = {
  title: string
  subTitle?: string
  allowedFileTypes: Blob[`type`][]
  onFileChange?(files: FileUpload[]): void
  onUpload?: UploadFuncType
  onDelete?: OnDeleteType
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  mode?: Modes
  isDisabled?: boolean
}

const UploadInput = ({
  title,
  subTitle,
  allowedFileTypes,
  onFileChange = () => notYetImplemented,
  onUpload,
  onDelete,
  inputProps,
  mode = Modes.SINGLE,
  isDisabled = false,
}: Props) => {
  const [uploads, dispatch] = useReducer(reducer, initialState)

  const handleDrop: DropzoneOptions[`onDrop`] = (acceptedFiles) => {
    const newUploads: FileUpload[] = acceptedFiles.map((file, index) => {
      const upload: FileUpload = {
        id: `upload-${uploads.length + index}`,
        file,
        isProcessing: false,
        progress: 0,
        isComplete: false,
        isError: false,
      }

      if (!allowedFileTypes.includes(file.type)) {
        upload.isError = true
        upload.error = new Error(`Invalid File Type.`)
      }

      return upload
    })

    if (onUpload !== undefined) {
      newUploads.forEach((upload) => {
        if (!upload.isError) {
          onUpload({
            file: upload.file,
            onProgress: (value) => {
              dispatch({
                type: Actions.UPDATE_FILE,
                payload: {
                  id: upload.id,
                  upload: {
                    progress: value,
                  },
                },
              })
            },
            onComplete: () => {
              dispatch({
                type: Actions.UPDATE_FILE,
                payload: {
                  id: upload.id,
                  upload: {
                    isProcessing: false,
                    isComplete: true,
                  },
                },
              })
            },
            onCreate: (sideCar) => {
              dispatch({
                type: Actions.UPDATE_FILE,
                payload: {
                  id: upload.id,
                  upload: {
                    isProcessing: true,
                    sideCar,
                  },
                },
              })
            },
            onError: (error) => {
              dispatch({
                type: Actions.UPDATE_FILE,
                payload: {
                  id: upload.id,
                  upload: {
                    isError: true,
                    error,
                  },
                },
              })
            },
          })
        }
      })
    }

    dispatch({
      type: Actions.ADD_FILES,
      payload: newUploads,
    })
  }

  const handleDelete: OnDeleteType = (upload) => {
    onDelete && onDelete(upload)
    dispatch({
      type: Actions.REMOVE_FILE,
      payload: upload.id,
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    disabled: isDisabled,
    onDrop: handleDrop,
    maxFiles: 1000,
  })

  useEffect(() => {
    onFileChange(uploads)
  }, [uploads, onFileChange])

  const content = useMemo(() => {
    if (mode === Modes.SINGLE && uploads.length === 1) {
      const file = uploads[0].file

      return (
        <img
          src={URL.createObjectURL(file)}
          style={{ maxWidth: `300px` }}
          alt="preview"
        />
      )
    }

    return (
      <>
        <input
          {...getInputProps()}
          {...inputProps}
          style={{ display: `none` }}
        />
        <UploadIcon
          htmlColor={`#C4C4C4`}
          sx={{
            fontSize: '2.7rem',
          }}
        ></UploadIcon>
        <Typography>Drag and Drop Here</Typography>
        <Typography fontSize={`1.143rem`} lineHeight={`1.786rem`}>
          or
        </Typography>
        <Button
          disabled={isDisabled}
          component={`span`}
          variant={`secondary`}
          sx={{
            borderColor: theme.palette.button.main,
            '&:hover': {
              borderColor: theme.palette.button.main,
            },
          }}
        >
          Browse Files
        </Button>
        <Typography fontSize={`0.857rem`} lineHeight={`1.286rem`}>
          Accepted filed types : GIF, PNG, JPEG
        </Typography>
      </>
    )
  }, [getInputProps, inputProps, mode, uploads, isDisabled])

  return (
    <Box
      sx={{
        marginBottom: 4,
        display: `grid`,
        gap: 2,
      }}
    >
      <Typography>{title}</Typography>
      {subTitle && (
        <Typography
          sx={{
            fontSize: `0.857rem`,
            lineHeight: `1.286rem`,
            mb: 2,
          }}
        >
          {subTitle}
        </Typography>
      )}
      <Box
        {...getRootProps()}
        style={{
          display: `grid`,
          gap: 0.5,
          border: `1px dashed #C4C4C4`,
          color: `#C4C4C4`,
          padding: 2,
          justifyItems: `center`,
        }}
      >
        {content}
      </Box>
      {mode === Modes.MULTIPLE && (
        <Box
          sx={{
            height: '250px',
            overflowY: `auto`,
          }}
        >
          <UploadIndicatorContainer
            uploadFunc={onUpload}
            onDelete={handleDelete}
            uploads={uploads}
          ></UploadIndicatorContainer>
        </Box>
      )}
    </Box>
  )
}

export default UploadInput
