import {
  DocumentData,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'

export enum Variations {
  LEFT = `LEFT`,
  RIGHT = `RIGHT`,
}

type HomepageSlider = {
  id: string
  backgroundColor: string
  buttonLink: string
  buttonText: string
  imageUrl: string
  subTitle: string
  title: string
  variation: Variations
  textColor: string
}

export const firestoreConverter = {
  toFirestore({
    id,
    backgroundColor,
    buttonLink,
    buttonText,
    imageUrl,
    subTitle,
    title,
    variation,
    textColor,
  }: PartialWithFieldValue<HomepageSlider>): DocumentData {
    return {
      id,
      backgroundColor,
      buttonLink,
      buttonText,
      imageUrl,
      subTitle,
      title,
      variation,
      textColor,
    }
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): HomepageSlider {
    const homepageSlider = snapshot.data(options) as HomepageSlider
    return {
      ...homepageSlider,
      id: snapshot.id,
    }
  },
}

export default HomepageSlider
