import { Button } from '@mui/material'
import { isRailOpenVar, railModeVar, BuyNowButtonRailId } from 'apollo/cache'
import { RailModes } from '../Rails'

type Props = {
  id: string
  collectionId: string
  sellerId: string
}

const BuyButton = ({ id, collectionId, sellerId }: Props) => {
  const handleOpen = () => {
    isRailOpenVar(true)
    railModeVar(RailModes.BUY)
    if (id && collectionId && sellerId) {
      BuyNowButtonRailId({
        id,
        collectionId,
        sellerId,
      })
    }
  }
  return <Button onClick={handleOpen}>Buy Now</Button>
}

export default BuyButton
