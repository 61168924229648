import { getDoc } from 'firebase/firestore'
import { userRef } from 'firebaseAdapter/refs'
import User from 'types/User'

const getUser = async (id: string): Promise<User> => {
  try {
    const snapshot = await getDoc(userRef(id))

    if (!snapshot.exists()) {
      throw new Error(`User not found!`)
    }

    return {
      id,
      ...snapshot.data(),
    } as User
  } catch (error) {
    throw new Error(`Failed to retrieve User with id of ${id}: ${error}`)
  }
}

export default getUser
