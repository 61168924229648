import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useState } from 'react'
import HomeIcon from '@mui/icons-material/Home'
import { useNavigate } from 'react-router-dom'
import { NavEntry } from 'hooks/useNavigationSchema'

type Props = {
  menus: NavEntry[]
}

const MobileNav = ({ menus }: Props) => {
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const menuItems = menus.map((menu) => {
    if ('Component' in menu) {
      return <ListItem>{menu.Component}</ListItem>
    }

    const items = menu?.items?.map(({ onClick, text, Icon }, itemIndex) => (
      <ListItemButton
        key={itemIndex}
        sx={{
          pl: 4,
          color: `white`,
        }}
        onClick={onClick}
      >
        <ListItemIcon
          sx={{
            color: `white`,
          }}
        >
          {Icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: `white`,
            fontSize: `18px`,
            lineHeight: `22px`,
            fontWeight: 600,
            textTransform: `uppercase`,
          }}
          primary={text}
        ></ListItemText>
      </ListItemButton>
    ))

    return [
      <ListItem>
        <ListItemText
          primaryTypographyProps={{
            color: `white`,
            fontSize: `20px`,
            lineHeight: `30px`,
            fontWeight: 700,
          }}
          primary={menu.title}
        />
      </ListItem>,
      <List>{items}</List>,
    ]
  })

  return (
    <Box
      sx={{
        display: {
          xs: `block`,
          md: `none`,
        },
      }}
    >
      <IconButton
        sx={{
          color: `white`,
        }}
        onClick={handleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        marginThreshold={0}
        PaperProps={{
          sx: {
            borderRadius: 0,
            maxWidth: `initial`,
            maxHeight: `calc(100% - 50px)`,
            width: `100vw`,
            backgroundColor: `black`,
          },
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
      >
        <List>
          <ListItemButton
            sx={{
              pl: 4,
              color: `white`,
            }}
            onClick={() => navigate(`/`)}
          >
            <ListItemIcon
              sx={{
                color: `white`,
              }}
            >
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                color: `white`,
                fontSize: `18px`,
                lineHeight: `22px`,
                fontWeight: 600,
                textTransform: `uppercase`,
              }}
              primary={`Home`}
            ></ListItemText>
          </ListItemButton>
        </List>
        {menuItems}
      </Menu>
    </Box>
  )
}

export default MobileNav
