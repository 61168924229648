import { Box } from '@mui/system'
import FeaturedCollectionsSlider from 'feature/FeaturedCollectionsSlider'
import SiteHero from 'feature/SiteHero'

const HomePage = () => {
  return (
    <Box>
      <SiteHero />
      <FeaturedCollectionsSlider />
    </Box>
  )
}

export default HomePage
