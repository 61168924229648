import { ExternalProvider, JsonRpcFetchFunc } from '@ethersproject/providers'
import { ethers } from 'ethers'
import { createContext, useContext } from 'react'

export type EthersProvider = ethers.providers.Web3Provider
export type EthersSigner = ethers.providers.JsonRpcSigner

type EthereumWindowObject = ExternalProvider | JsonRpcFetchFunc | undefined

interface Web3ProviderState {
  provider?: EthersProvider
  signer?: EthersSigner
}

export const initialize = () => {
  //@ts-ignore
  const ethereumWindowObject = window.ethereum as EthereumWindowObject
  const provider = ethereumWindowObject
    ? new ethers.providers.Web3Provider(ethereumWindowObject)
    : undefined
  const signer = provider?.getSigner()

  return {
    provider,
    signer,
  }
}

const initialState = {
  provider: undefined,
  signer: undefined,
}

const Context = createContext<Web3ProviderState>(initialState)

export const useWeb3Context = () => useContext(Context)

interface Web3ProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

const Web3Provider = ({ children }: Web3ProviderProps) => {
  return <Context.Provider value={initialize()}>{children}</Context.Provider>
}

export default Web3Provider
