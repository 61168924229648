import { Box } from '@mui/system'
import FeaturedNFTs from './FeaturedNFTs'
import NonFeaturedNfts from './NonFeaturedNfts'
import { useParams } from 'react-router-dom'
import Edition from '../../types/Edition'
import Collection from '../../types/Collection'
import { useEffect, useState } from 'react'
import { getEditions } from 'firebaseAdapter/actions'
import BreadCrumbs from 'feature/BreadCrumbs'

type PrimaryEdition = Edition | undefined
type PrimaryEditionsObj = {
  primary1: PrimaryEdition
  primary2: PrimaryEdition
  primary3: PrimaryEdition
}

const CollectionViewPage = () => {
  const { sellerId, collectionId } = useParams()
  const [featuredEditions, setFeaturedEditions] = useState<PrimaryEditionsObj>({
    primary1: undefined,
    primary2: undefined,
    primary3: undefined,
  })
  const [nonFeaturedEditions, setNonFeaturedEditions] = useState<Edition[]>([])
  const [collection, setCollection] = useState<Collection | undefined>(
    undefined
  )

  useEffect(() => {
    if (sellerId && collectionId) {
      getEditions({ sellerId, collectionId }).then((res) => {
        const { collection, editions } = res
        if (editions && collection) {
          const primary1 = editions.find((x) => x.id === collection.primary1)
          const primary2 = editions.find((x) => x.id === collection.primary2)
          const primary3 = editions.find((x) => x.id === collection.primary3)
          setFeaturedEditions({
            primary1,
            primary2,
            primary3,
          })
          setNonFeaturedEditions(editions)
          setCollection(collection)
        }
      })
    }
  }, [sellerId, collectionId])

  return (
    <Box>
      <BreadCrumbs
        page={'collectionView'}
        sellerId={sellerId}
        collectionId={collectionId}
      />
      <Box
        sx={{
          mt: 2,
          mb: 2,
        }}
      >
        <FeaturedNFTs
          featuredNfts={featuredEditions}
          collection={collection}
        ></FeaturedNFTs>
        <NonFeaturedNfts
          nonFeaturedNfts={nonFeaturedEditions}
          collection={collection}
        ></NonFeaturedNfts>
      </Box>
    </Box>
  )
}

export default CollectionViewPage
