import {
  DocumentData,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'

type Token = {
  // nftOwner: string
  multiNftOwner: string
  tokenUri: string
  tokenId: string
  supply: string
}

export const firestoreConverter = {
  toFirestore(token: PartialWithFieldValue<Token>): DocumentData {
    return token
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Token {
    const token = snapshot.data(options) as Token

    return token
  },
}

export default Token
