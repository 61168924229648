import { addDoc } from 'firebase/firestore'
import { editionRef, myNftRef } from 'firebaseAdapter/refs'
import { userVar } from 'apollo/cache'
import Edition from 'types/Edition'

type Params = {
  purchaseDate: string
  edition: Edition
}

const createMyNft = async ({ purchaseDate, edition }: Params) => {
  const user = userVar()
  try {
    const { id: editionId, sellerId, collectionId } = edition

    if (!user) {
      throw new Error(`User is undefined!`)
    }

    if (!collectionId) {
      throw new Error(`Collection Id is undefined!`)
    }

    await addDoc(myNftRef(user.id), {
      quantity: `1`,
      purchaseDate,
      edition: editionRef(sellerId, collectionId, editionId),
    })
  } catch (error) {
    throw new Error(`Error while attempting to create a new MyNft. ${error}`)
  }
}

export default createMyNft
