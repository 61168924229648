import { AppBar, Toolbar } from '@mui/material'
import Logo from 'feature/Logo'
import { Link } from 'react-router-dom'
import NavigationMenu from './components/NavigationMenu'

const AppNavBar = () => {
  return (
    <AppBar
      position={'static'}
      elevation={0}
      sx={{
        zIndex: 'appBar',
      }}
    >
      <Toolbar
        sx={{
          display: 'grid',
          gridTemplateColumns: `min-content 1fr auto`,
          justifyContent: `center`,
          gap: 2,
          backgroundColor: '#000000',
          height: {
            xs: `60px`,
            md: `auto`,
          },
        }}
      >
        <Link to={`/`}>
          <Logo />
        </Link>
        <div></div>
        <NavigationMenu />
      </Toolbar>
    </AppBar>
  )
}

export default AppNavBar
