import { Reducer } from 'react'
import { FileUpload } from './types'

export enum Actions {
  ADD_FILES = 'ADD_FILES',
  UPDATE_FILE = 'UPDATE_FILE',
  REMOVE_FILE = 'REMOVE_FILE',
  RESET = 'RESET',
}

type UploadPayload = {
  id: string
  upload: Partial<FileUpload>
}

type AddPayload = FileUpload[]

type DeletePayload = string

interface ReducerActions {
  type: Actions
  payload?: UploadPayload | AddPayload | DeletePayload
}

export const initialState = []

const useUploadsReducer: Reducer<FileUpload[], ReducerActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case Actions.UPDATE_FILE:
      const { id, upload } = action.payload as UploadPayload

      const index = state.findIndex((el) => el.id === id)
      const tempUploads = [...state]
      const tempUpload = {
        ...state[index],
        ...upload,
      }
      tempUploads[index] = tempUpload
      return tempUploads

    case Actions.ADD_FILES:
      const newFiles = action.payload as FileUpload[]

      return [...state, ...newFiles]
    case Actions.REMOVE_FILE:
      const deleteId = action.payload as DeletePayload
      const deleteIndex = state.findIndex((el) => el.id === deleteId)
      const modifiedFiles = [...state]
      modifiedFiles.splice(deleteIndex, 1)

      return modifiedFiles
    case Actions.RESET:
      return []
    default:
      return state
  }
}

export default useUploadsReducer
