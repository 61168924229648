import { useReactiveVar } from '@apollo/client'
import { userVar } from 'apollo/cache'
import { useNavigate } from 'react-router-dom'

const useAppLinks = () => {
  const navigate = useNavigate()
  const user = useReactiveVar(userVar)

  const myCollections = () => {
    if (user === undefined) {
      throw new Error(`Could not generate link. User is not defined!`)
    }
    navigate(`/collections/${user.id}`)
  }

  const createCollection = () => {
    if (user === undefined) {
      throw new Error(`Could not generate link. User is not defined!`)
    }
    navigate(`/collections/${user.id}/create`)
  }

  const createNFTsStep1 = () => {
    if (user === undefined) {
      throw new Error(`Could not generate link. User is not defined!`)
    }
    navigate(`/nfts/${user.id}/create`)
  }

  const createNFTsStep2 = () => {
    if (user === undefined) {
      throw new Error(`Could not generate link. User is not defined!`)
    }
    navigate(`/nfts/${user.id}/create-2`)
  }

  const createNFTsStep3 = () => {
    if (user === undefined) {
      throw new Error(`Could not generate link. User is not defined!`)
    }
    navigate(`/nfts/${user.id}/create-3`)
  }

  const editNFTsStep1 = (collectionId: string) => {
    if (user === undefined) {
      throw new Error(`Could not generate link. User is not defined!`)
    }
    navigate(`/nfts/${user.id}/edit/${collectionId}`)
  }

  const editNFTsStep2 = (collectionId: string) => {
    if (user === undefined) {
      throw new Error(`Could not generate link. User is not defined!`)
    }
    navigate(`/nfts/${user.id}/edit-2/${collectionId}`)
  }

  const editNFTsStep3 = (collectionId: string) => {
    if (user === undefined) {
      throw new Error(`Could not generate link. User is not defined!`)
    }
    navigate(`/nfts/${user.id}/edit-3/${collectionId}`)
  }

  const collectionViewPage = (collectionId: string) => {
    if (user === undefined) {
      throw new Error(`Could not generate link. User is not defined!`)
    }
    navigate(`/collections/${user.id}/view/${collectionId}`)
  }

  const editionDetailsPage = (
    userId: string,
    collectionId: string,
    editionId: string,
    tokenId?: string
  ) => {
    if (!tokenId) {
      navigate(`/editions/${userId}/${collectionId}/${editionId}`)
    } else {
      singleEditionDetailsPage(userId, collectionId, editionId, tokenId)
    }
  }

  const singleEditionDetailsPage = (
    userId: string,
    collectionId: string,
    editionId: string,
    tokenId: string
  ) => {
    navigate(`editions/${userId}/${collectionId}/${editionId}/${tokenId}`)
  }

  return {
    myCollections,
    createCollection,
    createNFTsStep1,
    createNFTsStep2,
    createNFTsStep3,
    editNFTsStep1,
    editNFTsStep2,
    editNFTsStep3,
    collectionViewPage,
    editionDetailsPage,
  }
}

export default useAppLinks
