import React from 'react'
import { useAppLinks } from 'hooks/routes'
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
} from '@mui/material'
import KebobMenu from '../KebobMenu/KebobMenu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddLinkIcon from '@mui/icons-material/AddLink'
import { Status } from 'types/Collection'
import { clipboardGenerator } from 'utils/clipboardGenertor'
import ShortcutIcon from '@mui/icons-material/Shortcut'
import { useNavigate } from 'react-router-dom'

type Props = {
  imgURL: string
  title: string
  description: string
  status: Status
  collectionId: string
  modalTogle: (collectionId: string) => void
  openDeleteModal: boolean
  sellerId: string
}

const CollectionDetailCard = ({
  imgURL,
  title,
  description,
  status,
  collectionId,
  modalTogle,
  sellerId,
}: Props) => {
  const navigate = useNavigate()
  const environment = process.env.REACT_APP_APPLICATION_ENV
  const linkEnding = `/collections/${sellerId}/view/${collectionId}`
  const link =
    environment === 'local'
      ? `localhost:3000${linkEnding}`
      : `https://marketplace.veriverse.com${linkEnding}`

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuTitles = [
    `Add NFT's`,
    `Edit NFT Details`,
    `Delete Collection`,
    `Generate Link`,
    `Go To`,
  ]
  const open = Boolean(anchorEl)

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleGoToClick = () => {
    navigate(`${linkEnding}`)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const kebabMenuPublished = [
    {
      title: menuTitles[3],
      icon: <AddLinkIcon />,
      onClick: () => {
        clipboardGenerator(link)
      },
    },
    {
      title: menuTitles[4],
      icon: <ShortcutIcon />,
      onClick: handleGoToClick,
    },
  ]

  const kebabMenuDraft = [
    {
      title: menuTitles[0],
      icon: <AddCircleOutlineIcon />,
      onClick: () => editNFTsStep1(collectionId),
    },
    {
      title: menuTitles[1],
      icon: <EditOutlinedIcon />,
      onClick: () => editNFTsStep2(collectionId),
    },
    {
      title: menuTitles[2],
      icon: <DeleteOutlineIcon />,
      onClick: () => {
        modalTogle(collectionId)
      },
    },
  ]

  const menu = status === Status.DRAFT ? kebabMenuDraft : kebabMenuPublished

  const { editNFTsStep1, editNFTsStep2 } = useAppLinks()
  return (
    <Card>
      <CardMedia
        component={'img'}
        alt={'Collection preview'}
        height={'200'}
        image={imgURL}
      ></CardMedia>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontSize={16} lineHeight={1.714} fontWeight={700}>
            {title}
          </Typography>
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        </Box>
        <KebobMenu
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          menuInfo={menu}
        />
        <Typography variant={`body2`} mb={2}>
          {description}
        </Typography>
        <Typography
          textTransform={`uppercase`}
          fontSize={12}
          lineHeight={1.214}
          fontWeight={700}
        >
          {status}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CollectionDetailCard
