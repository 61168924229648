import { updateDoc } from 'firebase/firestore'
import { collectionRef } from 'firebaseAdapter/refs'
import { FirestoreCollection } from 'types/Collection'

const updateCollection = async ({
  sellerId,
  id,
  payload,
}: {
  sellerId: string
  id: string
  payload: Partial<FirestoreCollection>
}) => {
  try {
    await updateDoc(collectionRef(sellerId, id), payload)
  } catch (error) {
    throw new Error(
      `Error while attempting to update a new Collection. ${error}`
    )
  }
}

export default updateCollection
