import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { client } from 'apollo/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from './App'
import theme from './assets/theme'
import reportWebVitals from './reportWebVitals'
import Web3Provider from 'providers/Web3Provider'

Sentry.init({
  dsn: 'https://4d2acc4a9c1a4e578cd2bc7df68cbfbc@o57583.ingest.sentry.io/6512206',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_APPLICATION_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
})

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <CssBaseline />
      <Web3Provider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </Web3Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
