import { Container } from '@mui/material'
import AppNavBar from 'feature/AppNavBar'
import Footer from 'feature/Footer'
import { useAppRoutes } from 'hooks/routes'
import Rail from './feature/Rails/index'
import * as am5 from '@amcharts/amcharts5'
import useAuthentication from 'hooks/useAuthentication'
import AuthDialog from 'feature/AuthDialog'
import initPendo from './initPendo'
import AppToast from 'feature/AppToast'
// import useSeeder from 'hooks/useSeeder'

import 'swiper/css/bundle'
import './assets/styles/base.scss'
import './assets/styles/swiper.scss'

function App() {
  // useSeeder(2, 2, 3)
  am5.addLicense(`AM5C196223670`)
  initPendo()
  const { routes } = useAppRoutes()

  const { isAuthedGated } = useAuthentication()

  if (!isAuthedGated) {
    return <AuthDialog />
  }

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        position: `relative`,
      }}
    >
      <AppNavBar />
      {routes}
      <Rail />
      <Footer />
      <AppToast />
    </Container>
  )
}

export default App
