import { Modes } from 'feature/EditionDetailsPage/hooks/useEditionDetailsPage'
import PriceBox from 'feature/PriceBox'
import BuyButton from 'feature/BuyButton'

type Props = {
  price: number
  secondaryPrice: number
  mode: Modes
  collectionId: string
  sellerId: string
  id: string
}

const PurchaseSection = ({
  price,
  secondaryPrice,
  mode,
  collectionId,
  sellerId,
  id,
}: Props) => {
  if (mode === Modes.MULTI) {
    return null
  }

  return (
    <>
      <PriceBox
        price={price.toString()}
        secondaryPrice={secondaryPrice.toString()}
      ></PriceBox>
      <BuyButton
        collectionId={collectionId}
        sellerId={sellerId}
        id={id}
      ></BuyButton>
    </>
  )
}

export default PurchaseSection
