import { listAll, ref, StorageReference } from 'firebase/storage'
import { CollectionImages, EditionImages, SellerImages } from './types'
import { storage } from './utils'

export const sellerImagesRef = (sellerId: string, imageType: SellerImages) =>
  ref(storage, `sellerImages/${sellerId}/${imageType}`)

export const collectionImagesRef = (
  collectionId: string,
  imageType: CollectionImages
) => ref(storage, `collectionImages/${collectionId}/${imageType}`)

export const editionImagesRef = (id: string, imageType: EditionImages) =>
  ref(storage, `editionImages/${id}/${imageType}`)

export const getLocationImageRef = async (
  folderRef: StorageReference
): Promise<StorageReference> => {
  const res = await listAll(folderRef)

  return res.items[0]
}
