import { getDoc } from 'firebase/firestore'
import { editionRef } from 'firebaseAdapter/refs'

const getEdition = async ({
  sellerId,
  collectionId,
  id,
}: {
  sellerId: string
  collectionId: string
  id: string
}) => {
  const editionSnapshot = await getDoc(editionRef(sellerId, collectionId, id))
  return editionSnapshot.data()
}

export default getEdition
