import { AlertProps } from '@mui/material'
import { appNotificationVar } from 'apollo/cache'

//TODO: make this a util.
export const sendNotification = ({
  message,
  severity,
}: {
  message: string
  severity: AlertProps['severity']
}) => {
  appNotificationVar({
    isOpen: true,
    message,
    severity,
  })
}

const useAppNotification = () => {
  return {
    sendNotification,
  }
}

export default useAppNotification
