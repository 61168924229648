import { doc, getDoc } from 'firebase/firestore'
import { Locations } from 'firebaseAdapter/types'
import { db } from 'firebaseAdapter/utils'
import Seller, { firestoreConverter as sellerConverter } from 'types/Seller'

const getSeller = async (id: string): Promise<Seller> => {
  try {
    const docSnapshot = await getDoc(
      doc(db, Locations.USERS, id).withConverter(sellerConverter)
    )

    if (!docSnapshot.exists()) {
      throw new Error(`Seller not found!`)
    }

    const data = docSnapshot.data() as Seller

    return {
      ...data,
      id,
      collections: [],
    }
  } catch (error) {
    throw new Error(`Failed to retrieve Seller with id of ${id}: ${error}`)
  }
}

export default getSeller
