import React from 'react'
import NFTPreviewCard from 'feature/NFTPreviewCard'
import { Variants } from '../../NFTPreviewCard'
import { Box, MenuItem } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FilterListIcon from '@mui/icons-material/FilterList'
import Collection from 'types/Collection'
import Edition from 'types/Edition'
import { useAppLinks } from 'hooks/routes'

export type Props = {
  nonFeaturedNfts: any
  collection: Collection | undefined
}

const NonFeaturesNfts = ({ nonFeaturedNfts, collection }: Props) => {
  const { editionDetailsPage } = useAppLinks()
  const [sortBy, setSortBy] = React.useState('')

  const nftsList = nonFeaturedNfts.map((nft: Edition) => {
    if (nft.collectionId === undefined) {
      throw new Error('collectionId is undefined')
    }
    const collectionId = nft.collectionId
    return (
      <NFTPreviewCard
        title={nft.name}
        description={nft.description}
        imagePath={nft.assetUrl}
        imageAlt={nft.assetUrl}
        editionsCount={nft.supply}
        onCardClick={() => {
          editionDetailsPage(nft.sellerId, collectionId, nft.id)
        }}
        variant={Variants.QUARTER}
      />
    )
  })

  const handleChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string)
  }

  return (
    <Box>
      <Box
        sx={{
          mb: 2,
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant={`h6`} fontWeight={'700'}>{`All ${
            collection?.name ? collection.name : ''
          } NFT's`}</Typography>
          <Typography variant={`h6`}>
            {collection?.description ? collection.description : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
            <Select
              value={sortBy}
              label="Age"
              onChange={handleChange}
              sx={{
                width: '200px',
                mr: 3,
              }}
            >
              <MenuItem value={'0'}>Titles A-Z</MenuItem>
              <MenuItem value={'1'}>Twenty</MenuItem>
              <MenuItem value={'2'}>Thirty</MenuItem>
            </Select>
          </FormControl>
          <FilterListIcon />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 3,
        }}
      >
        {nftsList}
      </Box>
    </Box>
  )
}

export default NonFeaturesNfts
