export type FileUpload = {
  id: string
  file: File
  isProcessing: boolean
  progress: number
  isComplete: boolean
  isError: boolean
  error?: Error
  sideCar?: Record<string, any>
}
export enum Modes {
  SINGLE,
  MULTIPLE,
}

export type UploadFuncType = (args: {
  file: File
  onProgress: (value: number) => void
  onCreate?(sideCar: Record<string, any>): void
  onUpdate?(sideCar: Record<string, any>): void
  onComplete?: () => void
  onError?(error: Error): void
}) => void

export type OnDeleteType = (upload: FileUpload) => void
