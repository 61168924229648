import { createTheme, ThemeOptions as MuiThemeOptions } from '@mui/material'
import '@fontsource/nunito-sans'
import '@fontsource/dosis'
import sx from '@mui/system/sx'
import { CSSProperties } from '@mui/styled-engine'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Error'
import ErrorIcon from '@mui/icons-material/Cancel'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true
  }
}

declare module '@mui/material/styles/createPalette' {
  type ButtonPaletteColorOptions = SimplePaletteColorOptions & {
    hover: string
  }

  interface Palette {
    button: ButtonPaletteColorOptions
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    button?: ButtonPaletteColorOptions
    neutral?: PaletteColorOptions
  }

  // allow configuration using `createTheme`
  interface ThemeOptions extends MuiThemeOptions {
    pallete?: {
      button?: ButtonPaletteColorOptions
      neutral?: PaletteColorOptions
    }
  }
}

// create theme in steps https://mui.com/material-ui/customization/theming/#api
let theme = createTheme({
  typography: {
    fontFamily: ['Nunito Sans', 'Helvetica', 'sans-serif'].join(','),
    h2: {
      fontSize: '1.429rem',
      lineHeight: '2.143rem',
      fontWeight: '700',
    },
  },
  palette: {
    primary: {
      main: '#214167',
    },
    secondary: {
      main: '#335B89',
      contrastText: '#ffffff',
    },
    neutral: {
      main: '#5C6269',
    },
    button: {
      main: '#1871E8',
      hover: '#335B89',
    },
    success: {
      main: '#28BA3F',
    },
    info: {
      main: '#325491',
    },
    warning: {
      main: '#FFBB0A',
    },
    error: {
      main: '#D43060',
    },
    divider: '#214167',
  },
  spacing: 10,
})

theme = createTheme(theme, {
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableRipple: true,
      },
      styleOverrides: {
        // @ts-ignore
        root: ({ ownerState }) => ({
          textTransform: 'none',
          '&.Mui-disabled': {
            borderColor: `#E0E8F0`,
          },
          '&.Mui-disabled .MuiButton-startIcon': {
            opacity: 0.3,
          },
          ...(ownerState.variant === `contained` &&
            ({
              fontWeight: '600',
              backgroundColor: theme.palette.button.main,
              ':hover': {
                backgroundColor: theme.palette.button.hover,
              },
            } as CSSProperties)),
          ...(ownerState.variant === `text` &&
            ({
              fontWeight: '600',
              color: theme.palette.button.main,
              ':hover': {
                color: theme.palette.button.hover,
              },
              '.MuiButton-startIcon': {
                color: `#555F7C`,
                marginRight: theme.spacing(2),
              },
            } as CSSProperties)),
        }),
      },
      variants: [
        {
          props: {
            variant: 'secondary',
          },
          style: sx({
            backgroundColor: 'white',
            color: theme.palette.neutral.main,
            border: `1px solid ${theme.palette.neutral.main}`,
            ':hover': {
              border: `1px solid ${theme.palette.primary.main}`,
              color: theme.palette.primary.main,
              backgroundColor: 'white',
            },
          }),
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: sx({
          color: `#5C6269`,
          fontSize: `14px`,
          lineHeight: `21px`,
          fontWeight: `600`,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: sx({
          position: 'relative',
          marginLeft: 0,
          '&.Mui-error': {
            paddingLeft: `17px`,
            '&::before': {
              content: `""`,
              height: `12px`,
              width: '12px',
              position: 'absolute',
              left: 0,
              top: `50%`,
              transform: `translate(0, -50%)`,
              backgroundColor: theme.palette.error.main,
              borderRadius: `100%`,
            },
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: sx({
          color: `#2A323C`,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: `#E0E8F0`,
            },
            '&:hover fieldset': {
              borderColor: `#335B89`,
            },
            '&.Mui-focused fieldset': {
              border: `1px solid #5C6269`,
            },
            '&.Mui-error fieldset': {
              borderColor: theme.palette.error.main,
            },
          },
        }),
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          borderRadius: `100%`,
          outline: `1px solid #465364`,
          color: `white`,
          text: {
            fill: `#465364`,
          },
          '&.Mui-active': {
            outline: `none`,
            color: theme.palette.button.main,
            text: {
              fill: `white`,
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: `24px`,
          lineHeight: `34px`,
          paddingTop: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingBottom: theme.spacing(1),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingBottom: theme.spacing(1),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: `none`,
        color: `#1871E8`,
      },
    },
    MuiSnackbar: {
      defaultProps: {
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: `top`,
          horizontal: `right`,
        },
      },
      styleOverrides: {
        root: {
          width: `100%`,
          maxWidth: `415px`,
          position: `absolute`,
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: `outlined`,
        iconMapping: {
          success: <CheckCircleIcon />,
          info: <InfoIcon />,
          warning: <WarningIcon />,
          error: <ErrorIcon />,
        },
      },
      styleOverrides: {
        root: {
          width: `100%`,
          backgroundColor: `white`,
          borderColor: `#D5DFE9`,
          position: `relative`,
          overflow: `hidden`,
          '&:after': {
            content: '""',
            position: `absolute`,
            width: `4px`,
            left: 0,
            top: 0,
            bottom: 0,
          },
          '&.MuiAlert-outlinedSuccess:after': {
            backgroundColor: theme.palette.success.main,
          },
          '&.MuiAlert-outlinedInfo:after': {
            backgroundColor: theme.palette.info.main,
          },
          '&.MuiAlert-outlinedWarning:after': {
            backgroundColor: theme.palette.warning.main,
          },
          '&.MuiAlert-outlinedError:after': {
            backgroundColor: theme.palette.error.main,
          },
          '& .MuiAlert-icon': {
            opacity: 1,
            fontSize: 20,
          },
          '& .MuiAlert-message': {
            fontSize: `12px`,
            lineHeight: `22px`,
            color: `black`,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: theme.palette.button.main,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
          paddingTop: theme.spacing(1),
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 0,
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 0,
          },
        },
      },
    },
  },
})

export default theme
