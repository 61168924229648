import { BigNumber, ethers } from 'ethers'
import { EthersSigner } from 'providers/Web3Provider'
// https://github.com/veritone/nft-marketplace-backend/blob/develop/pkg/blockchain/abi/Marketplace-abi.json

type Contract = {
  allowance(
    owner: string,
    spendor: string,
    overrides?: ethers.Overrides
  ): Promise<BigNumber>
  approve(address: string, amount: number): Promise<any>
} & ethers.Contract

const erc20contract = (address: string, signer: EthersSigner) => {
  const abi: string[] = [
    'function allowance(address, address) view returns (uint256)',
    'function approve(address, uint256) nonpayable returns (bool)',
  ]

  return new ethers.Contract(address, abi, signer) as Contract
}

export default erc20contract
