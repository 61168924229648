import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'
import parseEntityData from 'utils/parseEntityData'
import FirestoreEntity from './FirestoreEntity'

export enum Status {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED',
}

export enum MintType {
  FULL = `FULL`,
  LAZY = `LAZY`,
}

export type BaseCollection = {
  id: string
  name: string
  description: string
  bannerImageURL: string
  status: Status
  mintType: MintType
  createdAt: number
  primary1?: string
  primary2?: string
  primary3?: string
  contract: string | null
}

type Collection = BaseCollection & FirestoreEntity

export type FirestoreCollection = Omit<Collection, `id`> & {
  id?: string
}

export const firestoreConverter: FirestoreDataConverter<Collection> = {
  toFirestore(
    collection: PartialWithFieldValue<FirestoreCollection>
  ): DocumentData {
    return collection
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Collection => {
    const collection: Collection = {
      ...(snapshot.data(options) as FirestoreCollection),
      id: snapshot.id,
      ...parseEntityData(snapshot.ref),
    }
    return collection
  },
}

export default Collection
