import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import Edition from 'types/Edition'
import { useParams } from 'react-router-dom'
import getSingleCollectionBySeller from 'firebaseAdapter/actions/getSingleCollectionBySeller'
import { useNavigate } from 'react-router-dom'
import { Modes as PageModes } from 'feature/CreateNFTsPage'

type Props = {
  title: string
  editions: Edition[]
}

const columns: GridColDef<Edition>[] = [
  {
    field: 'assetUrl',
    headerName: ``,
    sortable: false,
    renderCell: ({ value }) => (
      <Box p={2}>
        <img src={value} alt={`thumbnail`} />
      </Box>
    ),
  },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'description', headerName: 'Description', flex: 4 },
  { field: 'supply', headerName: 'Editions', flex: 1 },
  { field: 'price', headerName: 'Price', flex: 1 },
]

const StepThree = ({ title, editions }: Props) => {
  const navigate = useNavigate()
  const { collectionId, sellerId } = useParams()
  useEffect(() => {
    if (collectionId && sellerId) {
      getSingleCollectionBySeller(sellerId, collectionId).then((res) => {
        if (res) {
          if (res.status === 'PUBLISHED' && PageModes.EDIT) {
            navigate(`/`)
          }
        }
      })
    }
  })

  return (
    <Box>
      <Typography fontWeight={700} lineHeight={2.429} fontSize={24}>
        {title}
      </Typography>
      <Box height={400}>
        <DataGrid columns={columns} rows={editions}></DataGrid>
      </Box>
    </Box>
  )
}

export default StepThree
