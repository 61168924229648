import { deleteDoc } from 'firebase/firestore'
import { editionRef } from 'firebaseAdapter/refs'
import { deleteObject } from 'firebase/storage'
import { editionImagesRef } from 'firebaseAdapter/storageRefs'
import { EditionImages } from 'firebaseAdapter/types'

const deleteEdition = async ({
  id,
  userId,
  collectionId,
}: {
  id: string
  userId: string
  collectionId: string
}) => {
  try {
    const promises = Promise.all([
      await deleteDoc(editionRef(userId, collectionId, id)),
      await deleteObject(editionImagesRef(id, EditionImages.MAIN)),
    ])

    return await promises
  } catch (error) {
    throw new Error(`Error while attempting to delete an Edition. ${error}`)
  }
}

export default deleteEdition
