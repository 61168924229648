import { doc, getDoc } from 'firebase/firestore'
import { Locations } from 'firebaseAdapter/types'
import { db } from 'firebaseAdapter/utils'

const checkGatePassword = async (password: string): Promise<boolean> => {
  const snapshot = await getDoc(doc(db, Locations.APP_SETTINGS, `settings`))

  if (!snapshot.exists()) {
    throw new Error(`Password not found`)
  }

  const settings = snapshot.data()

  return password === settings?.sitePassword
}

export default checkGatePassword
