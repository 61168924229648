import { addDoc, updateDoc } from 'firebase/firestore'
import { collectionRef, sellerCollectionsRef } from 'firebaseAdapter/refs'
import { getDownloadURL } from 'firebase/storage'
import { BaseCollection } from 'types/Collection'
import addUpdateCollectionBannerImage from '../addUpdateCollectionBannerImage'

const createCollection = async ({
  sellerId,
  bannerFile,
  ...sellerCollection
}: {
  sellerId: string
  bannerFile: File
} & Omit<BaseCollection, `bannerImageURL` | `editions` | `id`>) => {
  try {
    const { id } = await addDoc(
      sellerCollectionsRef(sellerId),
      sellerCollection
    )

    const { ref: imageRef } = await addUpdateCollectionBannerImage(
      id,
      bannerFile
    )

    const bannerImageURL = await getDownloadURL(imageRef)

    await updateDoc(collectionRef(sellerId, id), {
      bannerImageURL,
    })
  } catch (error) {
    throw new Error(
      `Error while attempting to create a new Collection. ${error}`
    )
  }
}

export default createCollection
