import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Upload: any
}

export type AdminSignature = {
  __typename?: 'AdminSignature'
  keyId: Scalars['String']
  signature: Scalars['String']
  signatureAlgorithm: Scalars['String']
}

export type Auction = {
  __typename?: 'Auction'
  createdAt: Scalars['Int']
  endTime: Scalars['Int']
  highestBidder: Scalars['String']
  minBid: Scalars['Int']
  owner: Scalars['String']
  payToken: Scalars['String']
  reservePrice: Scalars['Int']
  resulted: Scalars['Boolean']
  startTime: Scalars['Int']
  tokenAddress: Scalars['String']
  tokenId: Scalars['Int']
  updatedAt: Scalars['Int']
}

export type AuctionContract = {
  __typename?: 'AuctionContract'
  address: Scalars['String']
  bidWithdrawalLockTime: Scalars['Int']
  createdAt: Scalars['Int']
  isPaused: Scalars['Boolean']
  minBidIncrement: Scalars['Int']
  updatedAt: Scalars['Int']
}

export type Bid = {
  __typename?: 'Bid'
  bid: Scalars['Int']
  bidder: Scalars['String']
  creationDate: Scalars['Int']
  tokenAddress: Scalars['String']
  tokenId: Scalars['Int']
  withdrawn: Scalars['Boolean']
}

export type Collection = {
  __typename?: 'Collection'
  bannerImageUrl: Scalars['String']
  createdBy: Scalars['String']
  description: Scalars['String']
  mintType: Scalars['String']
  name: Scalars['String']
  status: Scalars['String']
}

export type Edition = {
  __typename?: 'Edition'
  assetUrl: Scalars['String']
  contract: Scalars['String']
  description: Scalars['String']
  dropDate: Scalars['Int']
  expireDate: Scalars['Int']
  launchDate: Scalars['Int']
  name: Scalars['String']
  price: Scalars['Int']
  royalty: Array<Royalty>
  supply: Scalars['Int']
}

export type Listing = {
  __typename?: 'Listing'
  buyer: Scalars['String']
  createdAt: Scalars['Int']
  expirationTime: Scalars['Int']
  payToken: Scalars['String']
  pricePerItem: Scalars['Int']
  purchaseStatus: Scalars['Boolean']
  quantity: Scalars['Int']
  startTime: Scalars['Int']
  tokenAddress: Scalars['String']
  tokenId: Scalars['Int']
  updatedAt: Scalars['Int']
}

export type MarketplaceContract = {
  __typename?: 'MarketplaceContract'
  address: Scalars['String']
  createdAt: Scalars['Int']
  isPaused: Scalars['Boolean']
  updatedAt: Scalars['Int']
}

export type Mint = {
  __typename?: 'Mint'
  address: Scalars['String']
  isPaused: Scalars['Boolean']
}

export type Minters = {
  __typename?: 'Minters'
  minterAddress: Scalars['String']
  tokenAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type MultiNftContract = {
  __typename?: 'MultiNftContract'
  address: Scalars['String']
  createdAt: Scalars['Int']
  isPaused: Scalars['String']
  masterUri: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
  updatedAt: Scalars['Int']
}

export type MultiNftFactory = {
  __typename?: 'MultiNftFactory'
  address: Scalars['String']
  createdAt: Scalars['Int']
  isPaused: Scalars['Boolean']
  mintFee: Scalars['Int']
  updatedAt: Scalars['Int']
}

export type MultiNftToken = {
  __typename?: 'MultiNftToken'
  createdAt: Scalars['Int']
  nftOwner: Scalars['String']
  supply: Scalars['Int']
  tokenId: Scalars['String']
  tokenUri: Scalars['String']
  updatedAt: Scalars['Int']
}

export type MultiTokenMintRequest = {
  __typename?: 'MultiTokenMintRequest'
  adminSignature: AdminSignature
  creationDate: Scalars['Int']
  deployAndMint: Scalars['Boolean']
  existingAddress?: Maybe<Scalars['String']>
  expirationDate: Scalars['Int']
  isApproved: Scalars['Int']
  isMinted: Scalars['Boolean']
  isRedeemed: Scalars['Boolean']
  name: Scalars['String']
  owner: Scalars['String']
  recipient: Scalars['String']
  requestId: Scalars['Int']
  statusMsg: Scalars['String']
  supply: Scalars['Int']
  symbol: Scalars['String']
  tokenMasterUri: Scalars['String']
  tokenUri: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Accepts an offer */
  acceptsOffer: Scalars['String']
  /** approved mint request. approves a mint request. Also uploads asset into */
  approveRequest: Scalars['String']
  /** Buys a listed item */
  buyItem: Scalars['String']
  /** Buys a listed item (LAZY) */
  buyItemLazy: Scalars['String']
  /** Cancels an auction (Only Admin) */
  cancelAuction: Scalars['String']
  /** Cancels a listings */
  cancelListingNftAddress: Scalars['String']
  /** Cancels an offer */
  cancelOffer: Scalars['String']
  /** Create auction with a lazy minted token */
  createAuctionLazy: Scalars['String']
  /** issues a create multi token nft contract blockchain call. For ADMIN & UNLIMITED_MINTER ONLY */
  createMultiNftContract: Scalars['String']
  /** issues a create multi token nft contract blockchain call with a signed multi token mint request */
  createMultiNftContractWithRequest: Scalars['String']
  /**
   * NFT:
   *
   * issues a create nft contract blockchain call. For ADMIN & UNLIMITED_MINTER ONLY
   */
  createNftContract: Scalars['String']
  /** issues a create nft contract blockchain call with a signed token mint request */
  createNftContractWithRequest: Scalars['String']
  /** Creates an Offer for a listed item */
  createOffer: Scalars['String']
  /** Creates auction */
  createsAuction: Scalars['String']
  /** Grants to an Ethereum address i.e., user/contract the role ADMIN. ADMIN ONLY. */
  grantAdminRole: Scalars['String']
  /** Grants to an Ethereum address i.e., user/contract the role FULL_MINTER. ADMIN ONLY. */
  grantFullMinterRole: Scalars['String']
  /** Grants to an Ethereum address i.e., user/contract the role LAZY_MINTER. ADMIN ONLY. */
  grantLazyMinterRole: Scalars['String']
  /** Grants to an Ethereum address i.e., user/contract the role UNLIMITED_MINTER. ADMIN ONLY. */
  grantUnlimitedMinterRole: Scalars['String']
  /**
   * Marketplace/Auction:
   * Lists an item on the marketplace
   */
  listItem: Scalars['String']
  /** list lazy mint token. Not minted yet, it is minted after purchase. */
  listItemLazy: Scalars['String']
  /** Mints a Multi NFT (ERC-1155) token. For ADMIN & UNLIMITED_MINTER ONLY */
  mintMultiNft: Scalars['String']
  /** mint a Multi NFT (ERC-1155) with a multi token mint request */
  mintMultiNftWithRequest: Scalars['String']
  /** Mints an NFT (ERC-721) token. For ADMIN & UNLIMITED_MINTER ONLY */
  mintNft: Scalars['String']
  /** mint an NFT (ERC-721) with a token mint request */
  mintNftWithRequest: Scalars['String']
  /**
   * NFT Colection
   *
   * Sets the mint status of an edition as "true"
   */
  nftIsMinted: Scalars['String']
  /** Pauses contract */
  pause: Scalars['String']
  /** Places a bid on a auction */
  placeBid: Scalars['String']
  /** Registers royalty for a NFT (ERC-1155) */
  registerMultiTokenRoyalty: Scalars['String']
  /** Registers royalty for a NFT (ERC-721) */
  registerRoyalty: Scalars['String']
  /** Registers an NFT token contract address on the NFT factory */
  registerTokenContract: Scalars['String']
  /** reject mint request. rejects a mint request and records rejection reason */
  rejectRequest: Scalars['String']
  /** Closes a finished auction and rewards the highst bidder */
  resultAuction: Scalars['String']
  /** Revokes ADMIN role. ADMIN ONLY. */
  revokeAdminRole: Scalars['String']
  /**
   * '
   *     Revokes FULL_MINTER role. ADMIN ONLY.
   */
  revokeFullMinterRole: Scalars['String']
  /** Revokes LAZY_MINTER role. ADMIN ONLY. */
  revokeLazyMinterRole: Scalars['String']
  /** Revokes UNLIMITED_MINTER role. ADMIN ONLY. */
  revokeUnlimitedMinter: Scalars['String']
  /** sets mint request mint status as true by ID */
  setTokenMintRequestAsMinted: Scalars['String']
  /** submits a mint request to the smart contract. Data comes from the frontend */
  submitMintRequest: Scalars['String']
  /** Resumes contract */
  unpause: Scalars['String']
  /** Updates the end time/expiration date of a given auction */
  updateAuctionEndTime: Scalars['String']
  /** Updates the reserve price of a given auction */
  updateAuctionReservePrice: Scalars['String']
  /** Updates the start time of a given auction */
  updateAuctionStartTime: Scalars['String']
  /** Updates bid withdrawl lock time globally on all auctions */
  updateBidWithdrawalLockTime: Scalars['String']
  /**
   * Firestore Mutations (Does not call Smart Contracts)
   *
   * Collections Collection
   *
   * Updates the description of a collection
   */
  updateCollectionDescription: Scalars['String']
  /** Updates the mint type of a collection */
  updateCollectionMintType: Scalars['String']
  /** Updates the status of a collection */
  updateCollectionStatus: Scalars['String']
  /**
   * Edition Colection
   *
   * Updates the description of an edition
   */
  updateEditionDescription: Scalars['String']
  /** Updates the drop date of an edition */
  updateEditionDropDate: Scalars['String']
  /** Updates the expiry date of an edition */
  updateEditionExpireDate: Scalars['String']
  /** Updates the launch date of an edition */
  updateEditionLaunchDate: Scalars['String']
  /** Updates Platform fee recipient */
  updateFeeRecipient: Scalars['String']
  /** Updates a listings */
  updateListing: Scalars['String']
  /** Updates listing start time */
  updateListingStartTime: Scalars['String']
  /** Updates min. bid increment globally on all auctions */
  updateMinBidIncrement: Scalars['String']
  /** updates multi token mint request. provided id is the mint request id from blockchain (not to be used) */
  updateMultiTokenMintRequest: Scalars['String']
  /** Updates platform fee */
  updatePlatformFee: Scalars['String']
  /** updates token mint request. provided id is the mint request id from blockchain (not to be used) */
  updateTokenMintRequest: Scalars['String']
  /**
   * Mint Requests:
   *
   * uploads provided file to IPFS and returns its hash (WiP) - Might not be necessary. Just upload when approved
   */
  uploadFile: Scalars['String']
  /** uploads img at a given imageURL to IPFS, creates metadata.json  and use CID, uploads metadata.json to IPFS and return CID */
  uploadToIPFS: Scalars['String']
  /** Validates if an item was sold */
  validateItemSold: Scalars['String']
  /** Withdraws a bid from an auction. Only the highest bidder can call. */
  withdrawBid: Scalars['String']
}

export type MutationAcceptsOfferArgs = {
  creator: Scalars['String']
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationApproveRequestArgs = {
  id: Scalars['Int']
  isLazy: Scalars['Boolean']
  isMulti: Scalars['Boolean']
}

export type MutationBuyItemArgs = {
  nftAddress: Scalars['String']
  owner: Scalars['String']
  payToken: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationBuyItemLazyArgs = {
  id: Scalars['Int']
  isMulti: Scalars['Boolean']
  payToken: Scalars['String']
}

export type MutationCancelAuctionArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationCancelListingNftAddressArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationCancelOfferArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationCreateAuctionLazyArgs = {
  bidAmount: Scalars['Int']
  endTimestamp: Scalars['Int']
  isMulti: Scalars['Boolean']
  minBidReserve: Scalars['Boolean']
  owner: Scalars['String']
  payToken: Scalars['String']
  requestId: Scalars['Int']
  reservePrice: Scalars['Int']
  startTimestamp: Scalars['Int']
}

export type MutationCreateMultiNftContractArgs = {
  masterUri: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
  token: Scalars['String']
}

export type MutationCreateMultiNftContractWithRequestArgs = {
  isLazy: Scalars['Boolean']
  requestId: Scalars['Int']
}

export type MutationCreateNftContractArgs = {
  name: Scalars['String']
  symbol: Scalars['String']
  token: Scalars['String']
}

export type MutationCreateNftContractWithRequestArgs = {
  isLazy: Scalars['Boolean']
  requestId: Scalars['Int']
}

export type MutationCreateOfferArgs = {
  deadline: Scalars['Int']
  nftAddress: Scalars['String']
  payToken: Scalars['String']
  pricePerItem: Scalars['Int']
  quantity: Scalars['Int']
  tokenId: Scalars['Int']
}

export type MutationCreatesAuctionArgs = {
  endTimestamp: Scalars['Int']
  minBidReserve: Scalars['Boolean']
  nftAddress: Scalars['String']
  payToken: Scalars['String']
  reservePrice: Scalars['Int']
  startTimestamp: Scalars['Int']
  tokenId: Scalars['Int']
}

export type MutationGrantAdminRoleArgs = {
  address: Scalars['String']
  userId: Scalars['String']
}

export type MutationGrantFullMinterRoleArgs = {
  address: Scalars['String']
  userId: Scalars['String']
}

export type MutationGrantLazyMinterRoleArgs = {
  address: Scalars['String']
  userId: Scalars['String']
}

export type MutationGrantUnlimitedMinterRoleArgs = {
  address: Scalars['String']
  userId: Scalars['String']
}

export type MutationListItemArgs = {
  nftAddress: Scalars['String']
  payToken: Scalars['String']
  pricePerItem: Scalars['Int']
  quantity: Scalars['Int']
  startingPrice: Scalars['Int']
  tokenId: Scalars['Int']
}

export type MutationListItemLazyArgs = {
  isMulti: Scalars['Boolean']
  payToken: Scalars['String']
  pricePerItem: Scalars['Int']
  quantity: Scalars['Int']
  requestId: Scalars['Int']
  startingPrice: Scalars['Int']
}

export type MutationMintMultiNftArgs = {
  nftContractAddress: Scalars['String']
  quantity: Scalars['Int']
  receiverAddress: Scalars['String']
  sender: Scalars['String']
  token: Scalars['String']
  tokenURI: Scalars['String']
}

export type MutationMintMultiNftWithRequestArgs = {
  isLazy: Scalars['Boolean']
  requestId: Scalars['Int']
}

export type MutationMintNftArgs = {
  nftContractAddress: Scalars['String']
  receiverAddress: Scalars['String']
  sender: Scalars['String']
  token: Scalars['String']
  tokenURI: Scalars['String']
}

export type MutationMintNftWithRequestArgs = {
  isLazy: Scalars['Boolean']
  requestId: Scalars['Int']
}

export type MutationNftIsMintedArgs = {
  collectionName: Scalars['String']
  editionName: Scalars['String']
  tokenId: Scalars['Int']
  userEmail: Scalars['String']
}

export type MutationPlaceBidArgs = {
  bidAmount: Scalars['Int']
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationRegisterMultiTokenRoyaltyArgs = {
  creator: Scalars['String']
  feeRecipient: Scalars['String']
  nftAddress: Scalars['String']
  royalty: Scalars['Int']
}

export type MutationRegisterRoyaltyArgs = {
  nftAddress: Scalars['String']
  royalty: Scalars['Int']
  tokenId: Scalars['Int']
}

export type MutationRegisterTokenContractArgs = {
  tokenContractAddress: Scalars['String']
}

export type MutationRejectRequestArgs = {
  id: Scalars['Int']
  isLazy: Scalars['Boolean']
  isMulti: Scalars['Boolean']
  rejectionReason: Scalars['String']
}

export type MutationResultAuctionArgs = {
  nftAddress: Scalars['String']
  owner: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationRevokeAdminRoleArgs = {
  address: Scalars['String']
  userId: Scalars['String']
}

export type MutationRevokeFullMinterRoleArgs = {
  address: Scalars['String']
  userId: Scalars['String']
}

export type MutationRevokeLazyMinterRoleArgs = {
  address: Scalars['String']
  userId: Scalars['String']
}

export type MutationRevokeUnlimitedMinterArgs = {
  address: Scalars['String']
  userId: Scalars['String']
}

export type MutationSetTokenMintRequestAsMintedArgs = {
  id: Scalars['Int']
  isLazy: Scalars['Boolean']
  isMulti: Scalars['Boolean']
}

export type MutationSubmitMintRequestArgs = {
  deployAndMint: Scalars['Boolean']
  existingAddress: Scalars['String']
  expirationDate: Scalars['Int']
  isLazy: Scalars['Boolean']
  isMulti: Scalars['Boolean']
  masterUri: Scalars['String']
  name: Scalars['String']
  owner: Scalars['String']
  recipient: Scalars['String']
  supply: Scalars['Int']
  symbol: Scalars['String']
  tokenUri: Scalars['String']
}

export type MutationUpdateAuctionEndTimeArgs = {
  endTimestamp: Scalars['Int']
  nftAddress: Scalars['String']
  owner: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationUpdateAuctionReservePriceArgs = {
  nftAddress: Scalars['String']
  owner: Scalars['String']
  reservePrice: Scalars['Int']
  tokenId: Scalars['Int']
}

export type MutationUpdateAuctionStartTimeArgs = {
  nftAddress: Scalars['String']
  owner: Scalars['String']
  startTimestamp: Scalars['Int']
  tokenId: Scalars['Int']
}

export type MutationUpdateBidWithdrawalLockTimeArgs = {
  bidWithdrawalLockTime: Scalars['Int']
}

export type MutationUpdateCollectionDescriptionArgs = {
  collectionName: Scalars['String']
  description: Scalars['String']
}

export type MutationUpdateCollectionMintTypeArgs = {
  collectionName: Scalars['String']
  mintType: Scalars['String']
}

export type MutationUpdateCollectionStatusArgs = {
  collectionName: Scalars['String']
  status: Scalars['String']
}

export type MutationUpdateEditionDescriptionArgs = {
  description: Scalars['String']
  editionName: Scalars['String']
}

export type MutationUpdateEditionDropDateArgs = {
  editionName: Scalars['String']
  timestamp: Scalars['Int']
}

export type MutationUpdateEditionExpireDateArgs = {
  editionName: Scalars['String']
  timestamp: Scalars['Int']
}

export type MutationUpdateEditionLaunchDateArgs = {
  editionName: Scalars['String']
  timestamp: Scalars['Int']
}

export type MutationUpdateFeeRecipientArgs = {
  feeRecipient: Scalars['String']
}

export type MutationUpdateListingArgs = {
  newPrice: Scalars['Int']
  nftAddress: Scalars['String']
  payToken: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationUpdateListingStartTimeArgs = {
  listerAddress: Scalars['String']
  nftAddress: Scalars['String']
  startTime: Scalars['Int']
  tokenId: Scalars['Int']
}

export type MutationUpdateMinBidIncrementArgs = {
  minBidIncrement: Scalars['Int']
}

export type MutationUpdateMultiTokenMintRequestArgs = {
  existingAddress: Scalars['String']
  id: Scalars['Int']
  name: Scalars['String']
  owner: Scalars['String']
  recipient: Scalars['String']
  supply: Scalars['Int']
  symbol: Scalars['String']
  tokenMasterUri: Scalars['String']
  tokenUri: Scalars['String']
}

export type MutationUpdatePlatformFeeArgs = {
  platformFee: Scalars['Int']
}

export type MutationUpdateTokenMintRequestArgs = {
  existingAddress: Scalars['String']
  id: Scalars['Int']
  name: Scalars['String']
  owner: Scalars['String']
  recipient: Scalars['String']
  symbol: Scalars['String']
  tokenUri: Scalars['String']
}

export type MutationUploadFileArgs = {
  file: Scalars['Upload']
}

export type MutationUploadToIpfsArgs = {
  description: Scalars['String']
  imageUrl: Scalars['String']
  name: Scalars['String']
}

export type MutationValidateItemSoldArgs = {
  buyer: Scalars['String']
  nftAddress: Scalars['String']
  seller: Scalars['String']
  tokenId: Scalars['Int']
}

export type MutationWithdrawBidArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type Nft = {
  __typename?: 'Nft'
  isMinted: Scalars['Boolean']
  token: Scalars['String']
  tokenId: Scalars['Int']
}

export type NftContract = {
  __typename?: 'NftContract'
  address: Scalars['String']
  createdAt: Scalars['Int']
  isPaused: Scalars['Boolean']
  name: Scalars['String']
  symbol: Scalars['String']
  updatedAt: Scalars['Int']
}

export type NftFactory = {
  __typename?: 'NftFactory'
  address: Scalars['String']
  createdAt: Scalars['Int']
  isPaused: Scalars['Boolean']
  mintFee: Scalars['Int']
  updatedAt: Scalars['Int']
}

export type NftToken = {
  __typename?: 'NftToken'
  createdAt: Scalars['Int']
  nftOwner: Scalars['String']
  tokenId: Scalars['Int']
  tokenUri: Scalars['String']
  updatedAt: Scalars['Int']
}

export type Offer = {
  __typename?: 'Offer'
  createdAt: Scalars['Int']
  creator: Scalars['String']
  deadline: Scalars['Int']
  payToken: Scalars['String']
  pricePerItem: Scalars['Int']
  quantity: Scalars['Int']
  tokenAddress: Scalars['String']
  tokenId: Scalars['Int']
  updatedAt: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  /** Gets NFT (ERC-721) contract data (DB call) TODO */
  factory?: Maybe<NftFactory>
  /** Gets all existing auctions */
  getAllAuctions: Array<Auction>
  /** Gets all bids from an existing auction by nft address and token id */
  getAllBids: Array<Bid>
  /** Gets all collections */
  getAllCollections: Array<Collection>
  /** Gets all editions */
  getAllEditions: Array<Edition>
  /** Gets all editions of a given collection */
  getAllEditionsByCollection: Array<Edition>
  /** Gets all marketplace listings */
  getAllListings: Array<Listing>
  /** Gets all mint requests data (DB call) */
  getAllMultiTokenMintRequests: Array<MultiTokenMintRequest>
  /** Gets all token NFT (ERC-1155) contracts data (DB call) */
  getAllMultiTokenNft: Array<MultiNftToken>
  /** Gets all token NFT (ERC-721) contracts data (DB call) */
  getAllNft: Array<NftToken>
  /** Gets all NFTs of a given edition */
  getAllNftByEdition: Array<Nft>
  /** Get all existing offers */
  getAllOffers: Array<Offer>
  /** fetches all signed multi token mint requests */
  getAllSignedMultiTokenMintRequests: Array<MultiTokenMintRequest>
  /** fetches all signed token mint requests */
  getAllSignedTokenMintRequests: Array<TokenMintRequest>
  /** Gets all token history entries of an existing token */
  getAllTokenHistoryEntries: Array<TokenHistory>
  /** Gets all mint requests data (DB call) */
  getAllTokenMintRequests: Array<TokenMintRequest>
  /** Gets data from a auction by nft address */
  getAuctionByNftAddress: Auction
  /** Gets data from a auction by token id */
  getAuctionByTokenId: Auction
  /** Gets a bid from an existing auction by nft address and token id */
  getBid: Bid
  /** Gets a collection by name */
  getCollectionByName: Collection
  /**
   * Firestore Queries (Does not call Smart Contracts)
   *
   * Collections Collection
   *
   * Gets the description of a collection
   */
  getCollectionDescription: Scalars['String']
  /** Gets the mint type of a collection */
  getCollectionMintType: Scalars['String']
  /** Gets the status of a collection */
  getCollectionStatus: Scalars['String']
  /** Gets all collections that were created by a user */
  getCollectionsByCreator: Array<Collection>
  /** Gets the asset URL of an edition */
  getEditionAssetUrl: Scalars['String']
  /** Gets an edition by name */
  getEditionByName: Edition
  /** Gets the smart contract firestore document reference of an edition */
  getEditionContractDocRef: Scalars['String']
  /**
   * Editions Collection
   *
   * Gets the description of a collection
   */
  getEditionDescription: Scalars['String']
  /** Gets the drop date of an edition */
  getEditionDropDate: Scalars['Int']
  /** Gets the expiry date of an edition */
  getEditionExpireDate: Scalars['Int']
  /** Gets the launch date of an edition */
  getEditionLaunchDate: Scalars['Int']
  /** Gets the price of an edition */
  getEditionPrice: Scalars['Int']
  /** Gets the token supply of an edition */
  getEditionSupply: Scalars['Int']
  /** Gets highest bidder data from a auction (TODO: Improve at later date) */
  getHighestBidder: Scalars['String']
  /** returns latest request id from MintRequests */
  getLatestRequestId: Scalars['Int']
  /**
   * Token History
   *
   * Gets the lastest entry in the token history collection of an existing token
   */
  getLatestTokenHistoryEntry: TokenHistory
  /** Gets a listing by nft address and token id */
  getListingByNftAddressTokenId: Listing
  /** Gets the USD price of a certain amount of MATIC tokens */
  getMaticInUSD: Scalars['Int']
  /** fetches multi token mint request from database */
  getMultiTokenMintRequestDB: MultiTokenMintRequest
  /** fetches mint request from smart contracts */
  getMultiTokenMintRequestSC: MultiTokenMintRequest
  /** Gets a NFT (ERC-1155) data by ID (DB Call) */
  getMultiTokenNft: MultiNftToken
  /** Gets a NFT (ERC-721) data by ID (DB Call) */
  getNft: NftToken
  /** Gets an Nft by Token ID */
  getNftByTokenId: Nft
  /**
   * NFTs Collection
   *
   * Gets the firestore ref to the token document
   */
  getNftDocRef: Scalars['String']
  /** Gets the mint status of an Nft */
  getNftMintStatus: Scalars['Boolean']
  /** Get offer by nft address and token id */
  getOffer: Offer
  /**
   * Marketplace/Auction
   *
   * Gets payment token price
   */
  getPrice: Scalars['String']
  /** fetches token mint request from database */
  getTokenMintRequestDB: TokenMintRequest
  /** fetches mint request from the smart contracts */
  getTokenMintRequestSC: TokenMintRequest
  /** Checks if an Ethereum address i.e., user/contract possesses the role ADMIN */
  hasRoleAdmin: Scalars['String']
  /** Checks if an Ethereum address i.e., user/contract possesses the role FULL_MINTER */
  hasRoleFullMinter: Scalars['String']
  /** Checks if an Ethereum address i.e., user/contract possesses the role LAZY_MINTER */
  hasRoleLazyMinter: Scalars['String']
  /** Checks if an Ethereum address i.e., user/contract possesses the role UNLIMITED_MINTER */
  hasRoleMinterUnlimited: Scalars['String']
  /** Gets NFT (ERC-721) contract data (DB call) TODO */
  multiTokenNftFactory?: Maybe<MultiNftFactory>
  /** Gets whitelist contract daya (DB Call) TODO */
  whitelist?: Maybe<Whitelist>
}

export type QueryGetAllBidsArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetAllEditionsByCollectionArgs = {
  collectionName: Scalars['String']
}

export type QueryGetAllMultiTokenMintRequestsArgs = {
  isLazy: Scalars['Boolean']
}

export type QueryGetAllNftByEditionArgs = {
  editionId: Scalars['String']
}

export type QueryGetAllSignedMultiTokenMintRequestsArgs = {
  isLazy: Scalars['Boolean']
}

export type QueryGetAllSignedTokenMintRequestsArgs = {
  isLazy: Scalars['Boolean']
}

export type QueryGetAllTokenHistoryEntriesArgs = {
  tokenAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetAllTokenMintRequestsArgs = {
  isLazy: Scalars['Boolean']
}

export type QueryGetAuctionByNftAddressArgs = {
  nftAddress: Scalars['String']
}

export type QueryGetAuctionByTokenIdArgs = {
  tokenId: Scalars['Int']
}

export type QueryGetBidArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetCollectionByNameArgs = {
  collectionName: Scalars['String']
}

export type QueryGetCollectionDescriptionArgs = {
  collectionName: Scalars['String']
}

export type QueryGetCollectionMintTypeArgs = {
  collectionName: Scalars['String']
}

export type QueryGetCollectionStatusArgs = {
  collectionName: Scalars['String']
}

export type QueryGetCollectionsByCreatorArgs = {
  creatorFirebaseRef: Scalars['String']
}

export type QueryGetEditionAssetUrlArgs = {
  editionName: Scalars['String']
}

export type QueryGetEditionByNameArgs = {
  editionName: Scalars['String']
}

export type QueryGetEditionContractDocRefArgs = {
  editionName: Scalars['String']
}

export type QueryGetEditionDescriptionArgs = {
  editionName: Scalars['String']
}

export type QueryGetEditionDropDateArgs = {
  editionName: Scalars['String']
}

export type QueryGetEditionExpireDateArgs = {
  editionName: Scalars['String']
}

export type QueryGetEditionLaunchDateArgs = {
  editionName: Scalars['String']
}

export type QueryGetEditionPriceArgs = {
  editionName: Scalars['String']
}

export type QueryGetEditionSupplyArgs = {
  editionName: Scalars['String']
}

export type QueryGetHighestBidderArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetLatestTokenHistoryEntryArgs = {
  tokenAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetListingByNftAddressTokenIdArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetMaticInUsdArgs = {
  maticValue: Scalars['Int']
}

export type QueryGetMultiTokenMintRequestDbArgs = {
  id: Scalars['Int']
  isLazy: Scalars['Boolean']
}

export type QueryGetMultiTokenMintRequestScArgs = {
  id: Scalars['Int']
}

export type QueryGetMultiTokenNftArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetNftArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetNftByTokenIdArgs = {
  editionId: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetNftDocRefArgs = {
  editionId: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetNftMintStatusArgs = {
  editionId: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetOfferArgs = {
  nftAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type QueryGetPriceArgs = {
  payToken: Scalars['String']
}

export type QueryGetTokenMintRequestDbArgs = {
  id: Scalars['Int']
  isLazy: Scalars['Boolean']
}

export type QueryGetTokenMintRequestScArgs = {
  id: Scalars['Int']
}

export type QueryHasRoleAdminArgs = {
  address: Scalars['String']
}

export type QueryHasRoleFullMinterArgs = {
  address: Scalars['String']
}

export type QueryHasRoleLazyMinterArgs = {
  address: Scalars['String']
}

export type QueryHasRoleMinterUnlimitedArgs = {
  address: Scalars['String']
}

export enum Roles {
  Admin = 'ADMIN',
  Buyer = 'BUYER',
  FullMinter = 'FULL_MINTER',
  LazyMinter = 'LAZY_MINTER',
  Seller = 'SELLER',
  UnlimitedMinter = 'UNLIMITED_MINTER',
}

export type Royalties = {
  __typename?: 'Royalties'
  creator: Scalars['String']
  royalty: Scalars['Int']
  tokenAddress: Scalars['String']
  tokenId: Scalars['Int']
}

export type Royalty = {
  __typename?: 'Royalty'
  address: Scalars['String']
  royalty: Scalars['Int']
}

export type TokenHistory = {
  __typename?: 'TokenHistory'
  createdAt: Scalars['Int']
  from: Scalars['String']
  price: Scalars['Int']
  priceUsd: Scalars['Int']
  to: Scalars['String']
  type: Scalars['String']
}

export type TokenMintRequest = {
  __typename?: 'TokenMintRequest'
  adminSignature: AdminSignature
  creationDate: Scalars['Int']
  deployAndMint: Scalars['Boolean']
  existingAddress?: Maybe<Scalars['String']>
  expirationDate: Scalars['Int']
  isApproved: Scalars['Int']
  isMinted: Scalars['Boolean']
  isRedeemed: Scalars['Boolean']
  name: Scalars['String']
  owner: Scalars['String']
  recipient: Scalars['String']
  requestId: Scalars['Int']
  statusMsg: Scalars['String']
  symbol: Scalars['String']
  tokenUri: Scalars['String']
}

export type TreasuryManagement = {
  __typename?: 'TreasuryManagement'
  address: Scalars['String']
  addressRegistry: Scalars['String']
  mintFee: Scalars['String']
}

export type User = {
  __typename?: 'User'
  email: Scalars['String']
  enforcedRoyalty: Array<Scalars['String']>
  fullName: Scalars['String']
  mintRights: Scalars['String']
  nonce: Scalars['String']
  publicAddress: Scalars['String']
  role: Scalars['String']
}

export type Whitelist = {
  __typename?: 'Whitelist'
  address: Scalars['String']
  isPaused: Scalars['Boolean']
}

export type MintNftMutationVariables = Exact<{
  receiverAddress: Scalars['String']
  tokenURI: Scalars['String']
  nftContractAddress: Scalars['String']
  sender: Scalars['String']
  token: Scalars['String']
}>

export type MintNftMutation = { __typename?: 'Mutation'; mintNft: string }

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload']
}>

export type UploadFileMutation = { __typename?: 'Mutation'; uploadFile: string }

export type UploadToIpfsMutationVariables = Exact<{
  name: Scalars['String']
  description: Scalars['String']
  imageUrl: Scalars['String']
}>

export type UploadToIpfsMutation = {
  __typename?: 'Mutation'
  uploadToIPFS: string
}

export const MintNftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'mintNft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'receiverAddress' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tokenURI' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nftContractAddress' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sender' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mintNft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'receiverAddress' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'receiverAddress' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tokenURI' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tokenURI' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nftContractAddress' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nftContractAddress' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MintNftMutation, MintNftMutationVariables>
export const UploadFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadFileMutation, UploadFileMutationVariables>
export const UploadToIpfsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadToIPFS' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'imageUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadToIPFS' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'description' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'imageUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'imageUrl' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadToIpfsMutation,
  UploadToIpfsMutationVariables
>
