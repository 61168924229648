import React from 'react'

const FacebookIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1891 0.243164C5.14442 0.243164 0.243164 5.16972 0.243164 11.2456C0.243164 16.7616 4.28601 21.3166 9.55269 22.1122V14.1612H6.84441V11.2693H9.55269V9.34472C9.55269 6.15867 11.0969 4.76051 13.7315 4.76051C14.9928 4.76051 15.6609 4.85488 15.9763 4.89719V7.42141H14.1795C13.0613 7.42141 12.6706 8.4878 12.6706 9.68876V11.2693H15.9481L15.5039 14.1612H12.6706V22.1351C18.0131 21.4072 22.1351 16.8158 22.1351 11.2456C22.1351 5.16972 17.2342 0.243164 11.1891 0.243164Z"
        fill="white"
      />
    </svg>
  )
}

export default FacebookIcon
