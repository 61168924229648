import { InMemoryCache, makeVar } from '@apollo/client'
import { AppToastProps } from 'feature/AppToast'
import { Providers } from 'hooks/useAuthentication'
import User from 'types/User'
import { RailModes } from '../feature/Rails'

type BuyNowProps = {
  id: string
  collectionId: string
  sellerId: string
}

export const isAuthedGatedVar = makeVar<boolean>(false)
export const isAuthenticatedVar = makeVar<boolean>(false)
export const userVar = makeVar<User | undefined>(undefined)
export const authProviderVar = makeVar<Providers>(Providers.NONE)
export const isRailOpenVar = makeVar<boolean>(false)
export const railModeVar = makeVar<RailModes>(RailModes.BUY)
export const appNotificationVar = makeVar<AppToastProps>({
  message: ``,
  isOpen: false,
  severity: `success`,
})

export const BuyNowButtonRailId = makeVar<BuyNowProps>({
  id: ``,
  collectionId: ``,
  sellerId: '',
})

export const defaultDeliveryDateVar = makeVar<Date>(new Date(`9/15/2022`))

export const sellerWalletProviderVar = makeVar<Providers>(Providers.NONE)

export const cache = new InMemoryCache({
  typePolicies: {
    App: {
      fields: {
        isAuthedGated: {
          read: () => isAuthedGatedVar,
        },
        isAuthenticated: {
          read: () => isAuthenticatedVar,
        },
        isRailOpen: {
          read: () => isRailOpenVar,
        },
        railMode: {
          read: () => railModeVar,
        },
        railId: {
          read: () => BuyNowButtonRailId,
        },
      },
    },
  },
})
