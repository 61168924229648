import { FirestoreSeller } from 'types/Seller'

export type CreateSeller = FirestoreSeller & {
  heroImage: FileList
}

export enum Locations {
  USERS = `users`,
  COLLECTIONS = `collections`,
  EDITIONS = `editions`,
  APP_SETTINGS = `siteConfig`,
  HOME_PAGE_SLIDER = `homepageSlider`,
  MY_NFTS = `myNfts`,
}

export enum SellerImages {
  HERO = `hero`,
}

export enum CollectionImages {
  BANNER = `banner`,
}

export enum EditionImages {
  MAIN = `main`,
}
