import { doc, getDoc } from 'firebase/firestore'
import { sellerCollectionsRef } from 'firebaseAdapter/refs'
import Collection from 'types/Collection'

const getSingleCollectionsBySeller = async (
  sellerId: string,
  collectionId: string
): Promise<Collection> => {
  try {
    const collectionsSnapshot = await getDoc(
      doc(sellerCollectionsRef(sellerId), collectionId)
    )
    if (!collectionsSnapshot.exists()) {
      throw new Error(`Collection not found!`)
    } else {
      return collectionsSnapshot.data()
    }
  } catch (error) {
    throw new Error(
      `Unable to retrieve collection from seller with id of ${sellerId}. ${error}`
    )
  }
}

export default getSingleCollectionsBySeller
