import { Box, BoxProps, Typography } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  title: string
  sx?: BoxProps[`sx`]
  children: ReactNode
}

const SectionWrapper = ({ title, sx, children }: Props) => {
  return (
    <Box p={2} border={`1px solid #E0E8F0`} sx={{ ...sx }}>
      <Typography fontSize={`20px`} lineHeight={`30px`} mb={1}>
        {title}
      </Typography>
      {children}
    </Box>
  )
}

export default SectionWrapper
