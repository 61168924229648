import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { UpdateEditionType } from 'feature/CreateNFTsPage'
import Edition from 'types/Edition'
import NftEditEntry from '../NftEditEntry'
import { useParams } from 'react-router-dom'
import getSingleCollectionBySeller from 'firebaseAdapter/actions/getSingleCollectionBySeller'
import { useNavigate } from 'react-router-dom'
import { Modes as PageModes } from 'feature/CreateNFTsPage'

type Props = {
  userId: string
  collectionIdentifier: string
  title: string
  editions: Edition[]
  updateEdition: UpdateEditionType
}

const StepTwo = ({
  userId,
  collectionIdentifier,
  title,
  editions,
  updateEdition,
}: Props) => {
  const navigate = useNavigate()
  const { collectionId, sellerId } = useParams()
  const items = editions.map((edition) => (
    <NftEditEntry
      key={edition.id}
      userId={userId}
      collectionId={collectionIdentifier}
      edition={edition}
      updateEdition={updateEdition}
    ></NftEditEntry>
  ))

  useEffect(() => {
    if (collectionId && sellerId) {
      getSingleCollectionBySeller(sellerId, collectionId).then((res) => {
        if (res) {
          if (res.status === 'PUBLISHED' && PageModes.EDIT) {
            navigate(`/`)
          }
        }
      })
    }
  })

  return (
    <Box>
      <Typography fontWeight={700} lineHeight={2.429} fontSize={24}>
        {title}
      </Typography>
      <Box
        sx={{
          height: `400px`,
          overflowY: `scroll`,
        }}
      >
        {items}
      </Box>
    </Box>
  )
}

export default StepTwo
