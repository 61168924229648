import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore'
import parseEntityData from 'utils/parseEntityData'
import FirestoreEntity from './FirestoreEntity'

type RoyaltyPair = {
  royalty: number
  address: string
}

export type BaseEdition = {
  id: string
  name: string
  description: string
  supply: number
  assetUrl: string
  launchDate: string
  dropDate: string
  expireDate: string
  uploadProgress: number
  price: number
  contract: string | null
  royalties: RoyaltyPair[]
  token: DocumentReference | null
  ipfsUri: string | null
}

type Edition = BaseEdition & FirestoreEntity

export const firestoreConverter = {
  toFirestore(edition: WithFieldValue<Edition>): DocumentData {
    return edition
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Edition {
    const edition: Edition = {
      ...(snapshot.data(options) as BaseEdition),
      id: snapshot.id,
      ...parseEntityData(snapshot.ref),
    }
    return edition
  },
}

export default Edition
