import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Modes } from 'feature/EditionDetailsPage/hooks/useEditionDetailsPage'
import Edition from 'types/Edition'
import PurchaseSection from './components/PriceBox'

type Props = Edition & {
  collectionName: string
  mode: Modes
}

const EditionTopSection = ({
  assetUrl,
  name,
  description,
  collectionName,
  price,
  mode,
  supply,
  collectionId,
  sellerId,
  id,
}: Props) => {
  const editionsText =
    mode === Modes.SINGLE ? `Edition 1/${supply}` : `Editions ${supply}`

  return (
    <>
      <img src={assetUrl} alt="Hero" />
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: 1.5,
          alignItems: `flex-start`,
        }}
      >
        <Typography fontSize={`18px`} lineHeight={`27px`}>
          {collectionName}
        </Typography>
        <Typography fontSize={`24px`} lineHeight={`34px`}>
          {name}
        </Typography>
        <Typography fontSize={`18px`} lineHeight={`27px`}>
          {editionsText}
        </Typography>
        <Typography>{description}</Typography>
        <PurchaseSection
          price={price}
          secondaryPrice={0}
          mode={mode}
          collectionId={collectionId ? collectionId : ''}
          sellerId={sellerId}
          id={id}
        ></PurchaseSection>
      </Box>
    </>
  )
}

export default EditionTopSection
