import { Box } from '@mui/system'
import EditionTopSection from 'feature/EditionTopSection'
import LoadingIndicator from 'feature/LoadingIndicator'
import EditionsTable from './components/EditionsTable'
import SingleDetails from './components/SingleDetails'
import useEditionDetailsPage from './hooks/useEditionDetailsPage'
import BreadCrumbs from 'feature/BreadCrumbs'
import { Modes } from 'feature/EditionDetailsPage/hooks/useEditionDetailsPage'

const EditionDetailsPage = () => {
  const { isLoading, edition, mode, collectionName } = useEditionDetailsPage()

  if (isLoading) {
    return (
      <Box
        sx={{
          display: `grid`,
          minHeight: `400px`,
        }}
      >
        <LoadingIndicator />
      </Box>
    )
  }

  if (!edition) {
    return null
  }

  return (
    <Box>
      <BreadCrumbs
        page={mode === Modes.SINGLE ? 'singleEdition' : 'multiEdition'}
        sellerId={edition.sellerId}
        collectionId={edition.collectionId}
        editionId={edition.id}
        editionName={edition.name}
      />
      <Box
        sx={{
          display: `grid`,
          gridTemplateColumns: `1fr 2fr`,
          gap: 4,
          py: 1,
          px: 3,
          height: `auto`,
        }}
      >
        <EditionTopSection
          collectionName={collectionName}
          mode={mode}
          {...edition}
        ></EditionTopSection>
        <SingleDetails mode={mode}></SingleDetails>
        <EditionsTable mode={mode} {...edition}></EditionsTable>
      </Box>
    </Box>
  )
}

export default EditionDetailsPage
