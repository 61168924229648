import { Box } from '@mui/system'
import { Modes } from 'feature/EditionDetailsPage/hooks/useEditionDetailsPage'
import React from 'react'
import DetailText from '../DetailText'
import SectionWrapper from '../SectionWrapper'

type Props = {
  mode: Modes
}

const SingleDetails = ({ mode }: Props) => {
  if (mode !== Modes.SINGLE) {
    return null
  }

  return (
    <SectionWrapper title={`NFT Details`}>
      <Box
        sx={{
          display: `grid`,
          fontWeight: `600`,
          gap: 1,
          gridTemplateColumns: `1fr 1fr`,
        }}
      >
        <DetailText>Owner</DetailText>
        <DetailText>OwnerName..12345</DetailText>
        <DetailText>Contact Address</DetailText>
        <DetailText>0xabdhf...7b23</DetailText>
        <DetailText>Token ID</DetailText>
        <DetailText>1234</DetailText>
        <DetailText>Token Standard</DetailText>
        <DetailText>ABC-123</DetailText>
        <DetailText>Blockchain</DetailText>
        <DetailText>Polygon</DetailText>
        <DetailText>Perk Available</DetailText>
        <DetailText>Yes</DetailText>
      </Box>
    </SectionWrapper>
  )
}

export default SingleDetails
