import React from 'react'

const TwitterIcon = () => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 2.93655C25.043 3.36155 24.015 3.64755 22.936 3.77655C24.038 3.11655 24.883 2.07155 25.281 0.825547C24.251 1.43655 23.109 1.88055 21.893 2.12055C20.92 1.08355 19.534 0.435547 18 0.435547C15.054 0.435547 12.666 2.82455 12.666 5.76955C12.666 6.18755 12.714 6.59555 12.804 6.98455C8.37102 6.76255 4.44102 4.63855 1.80902 1.41055C1.35102 2.19855 1.08802 3.11455 1.08802 4.09355C1.08802 5.94355 2.02902 7.57655 3.46002 8.53255C2.58602 8.50455 1.76302 8.26455 1.04402 7.86555C1.04402 7.88855 1.04402 7.90955 1.04402 7.93255C1.04402 10.5175 2.88202 12.6735 5.32302 13.1625C4.87602 13.2845 4.40402 13.3495 3.91702 13.3495C3.57402 13.3495 3.23902 13.3155 2.91402 13.2545C3.59302 15.3735 5.56302 16.9165 7.89702 16.9595C6.07202 18.3905 3.77202 19.2435 1.27202 19.2435C0.842024 19.2435 0.417023 19.2185 -0.000976562 19.1685C2.36002 20.6815 5.16302 21.5645 8.17602 21.5645C17.988 21.5645 23.352 13.4365 23.352 6.38755C23.352 6.15655 23.347 5.92655 23.337 5.69755C24.38 4.94455 25.285 4.00555 26 2.93655Z"
        fill="white"
      />
    </svg>
  )
}

export default TwitterIcon
