import { Box, Typography, useMediaQuery } from '@mui/material'
import HeroImage from 'assets/images/siteHero.png'
import theme from 'assets/theme'

const SiteHero = () => {
  const lgBreakpoint = useMediaQuery(theme.breakpoints.up(`lg`))

  return (
    <Box
      sx={{
        minHeight: `550px`,
        display: `grid`,
        alignContent: `center`,
        background: `center / cover no-repeat url(${HeroImage}) black`,
        p: lgBreakpoint ? 10 : 3,
      }}
    >
      <Box
        sx={{
          color: `white`,
          maxWidth: `500px`,
        }}
      >
        <Typography variant={`body2`} mb={1}>
          Welcome to
        </Typography>
        <Typography
          component={`h1`}
          fontFamily={`Dosis`}
          variant={`h1`}
          textTransform={`uppercase`}
          fontSize={49}
          mb={1}
        >
          Veritone NFT Marketplace
        </Typography>
        <Typography variant={`body2`} mb={3}>
          a Veriverse solution powered by Veritone
        </Typography>
        <Typography>
          Veritone NFT Marketplace is the trusted destination to mint and trade
          authentic NFTs. The Veritone NFT Marketplace brings together the
          biggest brands and brightest names in culture, media, and sports to
          create original digital collections and experiences.
        </Typography>
      </Box>
    </Box>
  )
}

export default SiteHero
