import {
  collection,
  collectionGroup,
  doc,
  DocumentReference,
  query,
  where,
} from 'firebase/firestore'
import { Locations } from './types'
import { db } from './utils'
import { firestoreConverter as userConverter } from 'types/User'
import {
  firestoreConverter as collectionConverter,
  MintType,
  Status,
} from 'types/Collection'
import { firestoreConverter as editionConverter } from 'types/Edition'
import { firestoreConverter as tokenConverter } from 'types/Token'
import { firestoreConverter as homepageSliderConverter } from 'types/HomepageSlider'
import { firestoreConverter as myNftConverter } from 'types/MyNft'

export const userRef = (id: string) =>
  doc(db, Locations.USERS, id).withConverter(userConverter)

export const publishedCollectionsRef = query(
  collectionGroup(db, Locations.COLLECTIONS),
  where(`status`, `==`, Status.PUBLISHED),
  where(`mintType`, `==`, MintType.FULL)
).withConverter(collectionConverter)

export const collectionRef = (userId: string, id: string) =>
  doc(db, Locations.USERS, userId, Locations.COLLECTIONS, id).withConverter(
    collectionConverter
  )

export const editionRef = (userId: string, collectionId: string, id: string) =>
  doc(
    db,
    Locations.USERS,
    userId,
    Locations.COLLECTIONS,
    collectionId,
    Locations.EDITIONS,
    id
  ).withConverter(editionConverter)

export const tokenRef = (ref: DocumentReference) =>
  doc(db, ref.path).withConverter(tokenConverter)

export const myNftRef = (userId: string) =>
  collection(db, Locations.USERS, userId, Locations.MY_NFTS).withConverter(
    myNftConverter
  )

export const sellerCollectionsRef = (userId: string) =>
  collection(db, Locations.USERS, userId, Locations.COLLECTIONS).withConverter(
    collectionConverter
  )

export const sellerCollectionEditionsRef = (
  userId: string,
  collectionId: string
) =>
  collection(
    db,
    Locations.USERS,
    userId,
    Locations.COLLECTIONS,
    collectionId,
    Locations.EDITIONS
  ).withConverter(editionConverter)

export const homepageSliderRef = () =>
  collection(db, Locations.HOME_PAGE_SLIDER).withConverter(
    homepageSliderConverter
  )
