import { useReactiveVar } from '@apollo/client'
import { isAuthenticatedVar, userVar } from 'apollo/cache'
import CollectionsPage from 'feature/CollectionsPage'
import CreateCollectionPage from 'feature/CreateCollectionPage'
import CreateNFTsPage, { Steps, Modes } from 'feature/CreateNFTsPage'
import HomePage from 'feature/HomePage'
import CollectionViewPage from 'feature/CollectionViewPage'
import { useMemo } from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'
import { Roles } from 'types/User'
import EditionDetailsPage from 'feature/EditionDetailsPage'

export enum RouteType {
  COLLECTIONS = 'collections',
  EDITIONS = 'editions',
  NFTS = 'nfts',
  NFT = 'nft',
}

const useAppRoutes = () => {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar)
  const user = useReactiveVar(userVar)

  type RouteNode = {
    title?: string
    breadCrumbs?: RouteNode[]
    location?: boolean
    titleAsId?: boolean
  } & RouteObject

  const { sellerRoutes, adminRoutes } = useMemo(() => {
    let sellerArr: RouteNode[] = []
    let adminArr: RouteNode[] = []
    if (isAuthenticated && user !== undefined) {
      const { role } = user

      switch (role) {
        case Roles.ADMIN:
          adminArr = [...adminArr]
          break

        case Roles.SELLER:
          sellerArr = [
            ...sellerArr,
            {
              path: `${RouteType.COLLECTIONS}/:sellerId`,
              title: 'Collections',
              element: <CollectionsPage />,
              breadCrumbs: [
                {
                  path: '/',
                  title: 'Home',
                },
                {
                  path: `${RouteType.COLLECTIONS}/:sellerId`,
                  title: 'Collections',
                  location: true,
                },
              ],
            },
            {
              path: `${RouteType.COLLECTIONS}/:sellerId/create`,
              title: 'Create Collection',
              element: <CreateCollectionPage />,
              breadCrumbs: [
                {
                  path: '/',
                  title: 'Home',
                },
                {
                  path: `${RouteType.COLLECTIONS}/:sellerId`,
                  title: 'Create Collection',
                  location: true,
                },
              ],
            },
            {
              path: `${RouteType.NFTS}/:sellerId/create`,
              title: 'Create NFTs step 1',
              element: (
                <CreateNFTsPage step={Steps.STEP1} mode={Modes.CREATE} />
              ),
              breadCrumbs: [
                {
                  path: '/',
                  title: 'Home',
                },
                {
                  path: `${RouteType.NFTS}/:sellerId/create`,
                  title: 'Create NFTs step 1',
                  location: true,
                },
              ],
            },
            {
              path: `${RouteType.NFTS}/:sellerId/create-2`,
              title: 'Create NFTs step 2',
              element: (
                <CreateNFTsPage step={Steps.STEP2} mode={Modes.CREATE} />
              ),
              breadCrumbs: [
                {
                  path: '/',
                  title: 'Home',
                },
                {
                  path: `${RouteType.NFTS}/:sellerId/create-2`,
                  title: 'Create NFTs step 2',
                  location: true,
                },
              ],
            },
            {
              path: `${RouteType.NFTS}/:sellerId/create-3`,
              title: 'Create NFTs step 3',
              element: (
                <CreateNFTsPage step={Steps.STEP3} mode={Modes.CREATE} />
              ),
              breadCrumbs: [
                {
                  path: '/',
                  title: 'Home',
                },
                {
                  path: `${RouteType.NFTS}/:sellerId/create-3`,
                  title: 'Create NFTs step 3',
                  location: true,
                },
              ],
            },
            {
              path: `${RouteType.NFTS}/:sellerId/edit/:collectionId`,
              title: 'Edit NFTs step 1',
              element: <CreateNFTsPage step={Steps.STEP1} mode={Modes.EDIT} />,
              breadCrumbs: [
                {
                  path: '/',
                  title: 'Home',
                },
                {
                  path: `${RouteType.NFTS}/:sellerId/edit/:collectionId`,
                  title: 'Edit NFTs step 1',
                  location: true,
                },
              ],
            },
            {
              path: `${RouteType.NFTS}/:sellerId/edit-2/:collectionId`,
              title: 'Edit NFTs step 2',
              element: <CreateNFTsPage step={Steps.STEP2} mode={Modes.EDIT} />,
              breadCrumbs: [
                {
                  path: '/',
                  title: 'Home',
                },
                {
                  path: `${RouteType.NFTS}/:sellerId/edit-2/:collectionId`,
                  title: 'Edit NFTs step 2',
                  location: true,
                },
              ],
            },
            {
              path: `${RouteType.NFTS}/:sellerId/edit-3/:collectionId`,
              title: 'Edit NFTs step 3',
              element: <CreateNFTsPage step={Steps.STEP3} mode={Modes.EDIT} />,
              breadCrumbs: [
                {
                  path: '/',
                  title: 'Home',
                },
                {
                  path: `${RouteType.NFTS}/:sellerId/edit-3/:collectionId`,
                  title: 'Edit NFTs step 3',
                  location: true,
                },
              ],
            },
          ]
          break
      }
    }

    return {
      sellerRoutes: sellerArr,
      adminRoutes: adminArr,
    }
  }, [isAuthenticated, user])

  const routes = [
    {
      path: `${RouteType.COLLECTIONS}/:sellerId/view/:collectionId`,
      title: 'Collection View',
      element: <CollectionViewPage />,
    },
    {
      path: `${RouteType.EDITIONS}/:sellerId/:collectionId/:editionId`,
      title: 'Editions',
      element: <EditionDetailsPage />,
      breadCrumbs: [
        {
          path: '/',
          title: 'Home',
        },
      ],
    },
    {
      path: `${RouteType.EDITIONS}/:sellerId/:collectionId/:editionId/:tokenId`,
      title: 'NFT Detail',
      element: <EditionDetailsPage />,
      breadCrumbs: [
        {
          path: '/',
          title: 'Home',
        },
      ],
    },
    ...adminRoutes,
    ...sellerRoutes,
    {
      path: `*`,
      title: 'Home',
      element: <HomePage />,
    },
  ]

  return {
    routes: useRoutes(routes),
    data: routes,
  }
}

export default useAppRoutes
