import * as React from 'react'
import { TextField, Box } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

type Props = {
  label: string
  onChange(payload: string | null): void
  value: string | null
}

const MuiDatePicker = ({ label, onChange, value }: Props) => {
  const [dateValue, setDateValue] = React.useState<string | null>(null)

  const handleSetValue = (date: string | null) => {
    setDateValue(`${date}`)
    onChange(date)
  }

  return (
    <Box sx={{}}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={value || dateValue}
          onChange={(newValue) => {
            handleSetValue(newValue)
          }}
          renderInput={(params) => (
            <TextField sx={{ width: '100%' }} {...params} />
          )}
        />
      </LocalizationProvider>
    </Box>
  )
}

export default MuiDatePicker
