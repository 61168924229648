import { Box, Typography } from '@mui/material'

type Props = {
  collectionName: string
  assetUrl: string
  name: string
  supply: number
  description: string
}

const RailInfo = ({
  collectionName,
  assetUrl,
  name,
  supply,
  description,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        mb: 3,
      }}
    >
      <Box
        component={'img'}
        src={assetUrl}
        alt={name}
        sx={{
          width: '30%',
          height: '100%',
          marginRight: '1rem',
          borderRadius: '0.3rem',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {collectionName}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          {`${description} - ${name} - 1/${supply}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default RailInfo
