import { Box, Skeleton, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import Edition from 'types/Edition'
import PricingButtons from '../PricingButtons'

type Props = {
  edition: Edition
}

const RailPricing = ({ edition }: Props) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 3,
        }}
      >
        <Typography>Current Price</Typography>
        <Skeleton>
          <Typography>{'00.XXXX ($1000)'}</Typography>
        </Skeleton>
      </Box>
      <Typography
        sx={{
          fontSize: '12px',
        }}
      >
        Fees
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 3,
        }}
      >
        <Typography>Minting Fee</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Skeleton>
            <Typography>{'00.XXXX ($15)'}</Typography>
          </Skeleton>
          {/* <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            Refreshing in 3s
          </Typography> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 3,
        }}
      >
        <Typography>Minting Fee</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Skeleton>
            <Typography>{'00.XXXX ($2)'}</Typography>
          </Skeleton>
          {/* <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            Refreshing in 3s
          </Typography> */}
        </Box>
      </Box>
      <Divider
        sx={{
          mb: 3,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 3,
        }}
      >
        <Typography>Total</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Skeleton>
            <Typography>{'00.XXXX ($1017)'}</Typography>
          </Skeleton>
          {/* <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            Refreshing in 3s
          </Typography> */}
        </Box>
      </Box>
      <PricingButtons edition={edition}></PricingButtons>
    </Box>
  )
}

export default RailPricing
