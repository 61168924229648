import {
  Avatar,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { FileUpload, OnDeleteType } from 'uiComponents/UploadInput/types'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import ImageIcon from '@mui/icons-material/Image'
import { Box } from '@mui/system'
import useIndicatorLine from 'uiComponents/UploadInput/hooks/useIndicatorLine'

type Props = {
  upload: FileUpload
  onDelete?: OnDeleteType
}

const IndicatorLine = ({ upload, onDelete }: Props) => {
  const { fileName, progress, errorText, barColor } = useIndicatorLine(upload)

  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge={`end`}
          sx={{
            fontSize: `15px`,
          }}
          onClick={() => onDelete && onDelete(upload)}
        >
          <DeleteIcon fontSize={`inherit`} htmlColor={`#555F7C`} />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            backgroundColor: `white`,
            border: `3px solid rgba(0, 0, 0, 0.05)`,
            fontSize: `15px`,
          }}
        >
          <ImageIcon htmlColor={`#555F7C`} fontSize={`inherit`} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={fileName}
        primaryTypographyProps={{
          variant: `body2`,
          marginBottom: `2px`,
        }}
        secondaryTypographyProps={{
          component: `div`,
        }}
        secondary={
          <Box>
            <LinearProgress
              variant={`determinate`}
              value={progress}
              color={barColor}
              sx={{
                marginBottom: 1,
              }}
            ></LinearProgress>
            <Typography color={`error`} variant={`body2`}>
              {errorText}
            </Typography>
          </Box>
        }
      ></ListItemText>
    </ListItem>
  )
}

export default IndicatorLine
