import { ReactNode, useMemo } from 'react'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import HelpIcon from '@mui/icons-material/Help'
import ErrorIcon from '@mui/icons-material/Error'
import SignOutIcon from '@mui/icons-material/ExitToApp'
import MyNFTsIcon from '@mui/icons-material/PhotoLibrary'
import CollectionsIcon from '@mui/icons-material/Analytics'
import AccountIcon from '@mui/icons-material/AccountCircle'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { Roles } from 'types/User'
import notYetImplemented from 'utils/notYetImplemented'
import { useAppLinks } from './routes'
import railModeAction from 'feature/Rails/utils/railModeAction'
import { RailModes } from 'feature/Rails'
import { logoutUser } from './useAuthentication'

export type NavEntry = {
  showIfAuthenticated?: boolean
  userRoles?: Roles[]
} & (
  | NavMenu
  | {
      Component: ReactNode
    }
)

export type NavMenu = {
  id: string
  title: string
  Icon?: ReactNode
  onClick?: () => void
  items?: NavItem[]
  type?: 'menu' | 'button'
}

export type NavItem = {
  text: string
  Icon: ReactNode
  userRoles?: Roles[]
  onClick(): void
}

const useNavigationSchema = (): NavEntry[] => {
  const { createCollection, myCollections, createNFTsStep1 } = useAppLinks()

  return useMemo(
    () => [
      {
        id: `create_menu`,
        title: `Create`,
        showIfAuthenticated: true,
        userRoles: [Roles.SELLER],
        items: [
          {
            text: `Create Collection`,
            Icon: <AddIcon />,
            onClick: createCollection,
          },
          {
            text: `Create NFTs`,
            Icon: <AddIcon />,
            onClick: createNFTsStep1,
          },

          {
            text: `Manage Collections`,
            Icon: <AddIcon />,
            onClick: myCollections,
          },
        ],
      },
      {
        id: `resources_menu`,
        title: `Resources`,
        items: [
          {
            text: `FAQs`,
            Icon: <HelpIcon />,
            onClick: notYetImplemented,
          },
          {
            text: `Help Center`,
            Icon: <ErrorIcon />,
            onClick: notYetImplemented,
          },
        ],
      },
      {
        id: `account_menu`,
        title: `Account`,
        showIfAuthenticated: true,
        Icon: <AccountIcon />,
        items: [
          {
            text: `My NFTs`,
            Icon: <MyNFTsIcon />,
            onClick: notYetImplemented,
          },
          {
            userRoles: [Roles.SELLER],
            text: `Manage Collections`,
            Icon: <CollectionsIcon />,
            onClick: myCollections,
          },
          {
            text: `Sign Out`,
            Icon: <SignOutIcon />,
            onClick: logoutUser,
          },
        ],
      },
      {
        id: `wallet`,
        title: `Wallet`,
        Icon: <AccountBalanceWalletIcon />,
        onClick: () => railModeAction(RailModes.WALLET, true),
        type: 'button',
      },
    ],
    [createCollection, myCollections, createNFTsStep1]
  )
}

export default useNavigationSchema
