import {
  DocumentData,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'
import Collection from './Collection'
import User from './User'

type Seller = User & {
  id: string
  name: string
  slug: string
  heroImageURL: string
  heroDescription: string
  primaryColor: string
  secondaryColor: string
  collections: Collection[]
}

export type FirestoreSeller = Omit<Seller, `id` | `collections`>

export const firestoreConverter = {
  toFirestore(seller: PartialWithFieldValue<Seller>): DocumentData {
    return seller
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Seller {
    const seller = snapshot.data(options) as Seller
    return {
      ...seller,
      id: snapshot.id,
      collections: [],
    }
  },
}

export default Seller
