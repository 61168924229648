import { getDocs, query, where } from 'firebase/firestore'
import { sellerCollectionsRef } from 'firebaseAdapter/refs'
import Collection, { Status } from 'types/Collection'

const getCollectionsBySeller = async (
  sellerId: string
): Promise<Collection[]> => {
  try {
    const collectionsSnapshot = await getDocs(
      query(
        sellerCollectionsRef(sellerId),
        where('status', '!=', Status.DELETED)
      )
    )

    return collectionsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      nfts: [],
    }))
  } catch (error) {
    throw new Error(
      `Unable to retrieve seller collections with id of ${sellerId}. ${error}`
    )
  }
}

export default getCollectionsBySeller
