import React from 'react'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from '@mui/material'
import { Variants } from '../../NFTPreviewCard/index'

export type Props = {
  title: string
  description: string
  imagePath: string
  imageAlt: string
  detailsContent?: React.ReactNode
  variant?: Variants
  onClick(): void
}

const FullWidthNft = ({
  title,
  description,
  imagePath,
  detailsContent,
  imageAlt,
  onClick,
}: Props) => {
  return (
    <Card
      elevation={5}
      square={true}
      sx={{
        display: `flex`,
        mb: 2,
        borderRadius: 3,
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardMedia
          component={'img'}
          src={imagePath}
          alt={imageAlt}
          sx={{
            width: `50%`,
            height: `100%`,
            objectFit: `cover`,
          }}
        />
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            padding: `1rem`,
          }}
        >
          <CardContent
            sx={{
              display: `flex`,
              flexDirection: `column`,
            }}
          >
            <Typography variant="h4" fontWeight={`700`}>
              {title}
            </Typography>
            <Typography variant={`body2`} mb={2}>
              {description}
            </Typography>
            <ul>{detailsContent}</ul>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default FullWidthNft
