import { sendNotification } from 'hooks/useAppNotification'

const handleAppError = (
  error: Error | unknown | string,
  defaultMessage?: string
) => {
  let message = defaultMessage
    ? defaultMessage
    : `An Unexpected error has occured`
  const isError = error instanceof Error
  const isString = typeof error === `string`

  if (isError) {
    message = error.message
  } else if (isString) {
    message = error
  }

  sendNotification({
    message,
    severity: `error`,
  })

  if (isError) {
    throw error
  } else {
    throw new Error(message)
  }
}

export default handleAppError
