import { defaultDeliveryDateVar } from 'apollo/cache'

const notYetImplemented = (date?: Date) => {
  const defaultDate = defaultDeliveryDateVar()
  let message = `Feature coming ${defaultDate.toLocaleDateString(`en-us`)}`

  if (date instanceof Date) {
    message = `Feature coming on ${date.toLocaleDateString()}`
  }
  alert(message)
}

export default notYetImplemented
