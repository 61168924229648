import { LinearProgressProps } from '@mui/material'
import { useMemo } from 'react'
import { FileUpload } from '../types'

const useIndicatorLine = (
  upload: FileUpload
): {
  fileName: string
  progress: number
  errorText: string
  barColor: LinearProgressProps[`color`]
} => {
  const { file, isError, error, isComplete, progress } = upload

  const barColor = useMemo(() => {
    if (isError) {
      return `error`
    }

    if (isComplete) {
      return `success`
    }

    return `primary`
  }, [isError, isComplete])

  const errorText = useMemo(() => {
    if (isError) {
      return error ? error.message : `Uncaught Upload Error.`
    }

    return ` `
  }, [isError, error])

  return {
    fileName: file.name,
    progress,
    errorText,
    barColor,
  }
}

export default useIndicatorLine
