import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import MetamaskIcon from 'assets/icons/MetamaskIcon'
import { RailModes } from 'feature/Rails'
import railModeAction from 'feature/Rails/utils/railModeAction'
import WalletConnectConfirmation from 'feature/WalletConnectConfirmation'
import useAppNotification from 'hooks/useAppNotification'
import { useWeb3Context } from 'providers/Web3Provider'
import ProcessingButton from 'uiComponents/ProcessingButton'
import { authWeb3User, connectToWeb3Wallet } from 'utils/web3'
import { useReactiveVar } from '@apollo/client'
import { userVar } from 'apollo/cache'
import { Roles } from 'types/User'

type Props = {}

const ConnectView = (props: Props) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)
  const user = useReactiveVar(userVar)
  const { provider, signer } = useWeb3Context()
  const { sendNotification } = useAppNotification()

  const handleClose = async () => {
    setDialogIsOpen(false)
    setIsProcessing(false)
    railModeAction(RailModes.NONE, false)
  }

  const handleConfirmClose = () => {
    setDialogIsOpen(false)
    handleClose()
  }

  const handleConnectClick = async () => {
    setIsProcessing(true)
    try {
      if (!provider || !signer) {
        throw new Error(`Provider is undefined!`)
      }

      const address = await connectToWeb3Wallet(provider, signer)

      if (user && user.role === Roles.SELLER) {
        handleClose()
      } else {
        const { isNewUser } = await authWeb3User(address)

        if (isNewUser) {
          setDialogIsOpen(true)
        } else {
          handleClose()
        }
      }
    } catch (error) {
      let message = `Unexpected error while connecting to wallet.`
      if (error instanceof Error) {
        message = error.message
      }
      sendNotification({
        message: message,
        severity: `error`,
      })

      handleClose()
    }
  }

  return (
    <Box>
      <Typography fontSize={`20px`} lineHeight={`30px`} fontWeight={700}>
        Connect a Wallet
      </Typography>
      <Typography variant={`body2`}>
        Choose from the list of available wallet providers or create a new
        wallet.
      </Typography>
      <Box
        sx={{
          paddingTop: 3,
          display: `grid`,
          gap: 2,
        }}
      >
        <ProcessingButton
          variant={`secondary`}
          startIcon={<MetamaskIcon />}
          fullWidth
          isProcessing={isProcessing}
          onClick={handleConnectClick}
        >
          MetaMask
        </ProcessingButton>
      </Box>
      <WalletConnectConfirmation
        isOpen={dialogIsOpen}
        onClose={handleConfirmClose}
      ></WalletConnectConfirmation>
    </Box>
  )
}

export default ConnectView
