import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

const LoadingIndicator = () => {
  return (
    <Box
      sx={{
        display: `grid`,
        justifyContent: `center`,
        alignContent: `center`,
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default LoadingIndicator
