import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { BaseEdition } from 'types/Edition'
import ProcessingButton from 'uiComponents/ProcessingButton'
import useCreateNftsProps from './hooks/useCreateNftsProps'
import BreadCrumbs from 'feature/BreadCrumbs'
import { useParams } from 'react-router-dom'

export enum Steps {
  STEP1,
  STEP2,
  STEP3,
}

export enum Modes {
  CREATE = 'create',
  EDIT = 'edit',
}

export type UpdateEditionType = ({
  id,
  payload,
}: {
  id: string
  payload: Partial<BaseEdition>
}) => void

export type Props = {
  step: Steps
  mode: Modes
}

const CreateNFTsPage = ({ step, mode }: Props) => {
  const { sellerId } = useParams()
  const {
    onProceed,
    onBack,
    onSave,
    nextButtonText,
    backButtonText,
    saveButtonText,
    component,
    isValid,
    width,
    title,
    isLoading,
    collectionIdentifier,
  } = useCreateNftsProps({
    step,
    mode,
  })

  return (
    <Box>
      <BreadCrumbs
        page={`${title} - ${mode}`}
        collectionId={collectionIdentifier}
        sellerId={sellerId}
      />
      <Box
        sx={{
          maxWidth: width,
          margin: `0 auto`,
          paddingY: 5,
          paddingX: 2,
        }}
      >
        <Typography variant={`h2`} mb={2}>
          {title}
        </Typography>
        <Stepper activeStep={step}>
          <Step>
            <StepLabel>Upload NFTs</StepLabel>
          </Step>
          <Step>
            <StepLabel>NFT Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Finalize & Mint</StepLabel>
          </Step>
        </Stepper>
        <Box
          sx={{
            paddingTop: 4,
            paddingBottom: 3,
          }}
        >
          {component}
        </Box>
        <Box
          sx={{
            display: `flex`,
            justifyContent: `flex-end`,
            gap: 2,
            gridTemplateColumns: `repeat(2, 90px)`,
          }}
        >
          <Button variant={`secondary`} onClick={onBack} disabled={isLoading}>
            {backButtonText}
          </Button>
          <Button variant={`secondary`} onClick={onSave} disabled={isLoading}>
            {saveButtonText}
          </Button>
          <ProcessingButton
            onClick={onProceed}
            disabled={!isValid}
            isProcessing={isLoading}
          >
            {nextButtonText}
          </ProcessingButton>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateNFTsPage
