import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import MetamaskIcon from 'assets/icons/MetamaskIcon'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import { logoutUser } from 'hooks/useAuthentication'
import { Roles } from 'types/User'
import { userVar } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'

const ConnectedView = () => {
  const user = useReactiveVar(userVar)

  if (!user) {
    return null
  }

  const { role } = user

  return (
    <Box
      sx={{
        maxWidth: `250px`,
        display: `grid`,
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: `grid`,
          gridTemplateColumns: `auto 1fr auto`,
          gap: 2,
          alignItems: `center`,
        }}
      >
        <MetamaskIcon />
        <Typography fontSize={`16px`} lineHeight={`20px`} fontWeight={600}>
          MetaMask
        </Typography>
        <CheckCircleIcon fontSize={`small`} color="success"></CheckCircleIcon>
      </Box>
      {role === Roles.BUYER && (
        <Box>
          <Button
            onClick={logoutUser}
            variant={`text`}
            startIcon={<LogoutIcon />}
          >
            Log out
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default ConnectedView
