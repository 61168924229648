import Drawer from '@mui/material/Drawer'
import { isRailOpenVar, railModeVar } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'
import { Box } from '@mui/system'
import { IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import getPropsByRailType from './utils/getRailPropsByType'

export enum RailModes {
  NONE = `NONE`,
  BUY = 'BUY',
  SELL = 'SELL',
  WALLET = 'WALLET',
}

const SiteRail = () => {
  const isOpen = useReactiveVar(isRailOpenVar)
  const mode = useReactiveVar(railModeVar)
  const { title, component } = getPropsByRailType(mode)

  const handleClose = () => {
    isRailOpenVar(false)
    railModeVar(RailModes.NONE)
  }

  return (
    <Drawer anchor={'right'} open={isOpen}>
      <Box
        sx={{
          width: '560px',
          paddingY: 2,
          paddingX: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
          }}
        >
          <Typography
            fontSize={`18px`}
            lineHeight={`24px`}
            textTransform={`uppercase`}
            fontWeight={`700`}
            color={`#5C6269`}
          >
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {component}
      </Box>
    </Drawer>
  )
}

export default SiteRail
