import { ethers } from 'ethers'
import { EthersSigner } from 'providers/Web3Provider'

// https://github.com/veritone/nft-marketplace-backend/blob/develop/pkg/blockchain/abi/MultiTokenFactory-abi.json

type Contract = {
  createNFTContract(
    name: string,
    symbol: string,
    uri: string,
    token: string
  ): Promise<any>
} & ethers.Contract

const multiTokenNFTFactoryContract = (signer: EthersSigner) => {
  const abi: string[] = [
    'function createNFTContract(string, string, string, address) payable',
    'event ContractCreated1155(address creator, address nft, string name, string symbol, string tokenUri1155)',
  ]

  const contract = new ethers.Contract(
    process.env.REACT_APP_CONTRACT_MULTI_TOKEN_NFT_FACTORY_ADDRESS,
    abi,
    signer
  )

  return contract as Contract
}

export default multiTokenNFTFactoryContract
