import { sellerWalletProviderVar, userVar } from 'apollo/cache'
import { signInWithCustomToken } from 'firebase/auth'
import {
  auth,
  getNonce,
  verifyNonce as cloudVerify,
  web3Login,
} from 'firebaseAdapter/utils'
import { Providers } from 'hooks/useAuthentication'
import { EthersProvider, EthersSigner } from 'providers/Web3Provider'
import { Roles } from 'types/User'
import * as contracts from './contracts'
import mintCollection from './mintCollection'

const connectToWeb3Wallet = async (
  provider: EthersProvider,
  signer: EthersSigner
): Promise<string> => {
  const user = userVar()
  await provider.jsonRpcFetchFunc('eth_requestAccounts')
  const address = await signer.getAddress()

  if (user && user.role === Roles.SELLER) {
    handleSellerConnect(provider)
  }

  return address
}

const handleSellerConnect = (provider: EthersProvider) => {
  if (provider.provider.isMetaMask) {
    sellerWalletProviderVar(Providers.METAMASK)
  } else {
    throw new Error(`Only Metamask is currently supported!`)
  }
}

const handleError = (error: unknown | Error) => {
  if (error instanceof Error) {
    throw new Error(error.message)
  } else {
    const unhandledError = new Error(`Unexpected Error`)
    throw unhandledError
  }
}

const signData = async (
  message: string,
  provider: EthersProvider,
  walletAddress: string
): Promise<string> => {
  try {
    return (await provider.jsonRpcFetchFunc(`personal_sign`, [
      walletAddress,
      message,
    ])) as string
  } catch (error) {
    return handleError(error)
  }
}

const getUserNonce = async (address: string): Promise<string> => {
  try {
    const res = await getNonce({
      address,
    })
    const nonce = res.data as string

    return nonce
  } catch (error) {
    throw new Error(`Unable to generate user nonce.`)
  }
}

const verifyNonce = async (message: string, address: string): Promise<void> => {
  try {
    const res = await cloudVerify({
      address,
      message,
    })

    const token = res.data as string

    await signInWithCustomToken(auth, token)
  } catch (error) {
    return handleError(error)
  }
}

type AuthWeb3UserReturn = {
  isNewUser: boolean
}

const authWeb3User = async (address: string): Promise<AuthWeb3UserReturn> => {
  let isNewUser = false

  try {
    const res = await web3Login({
      address,
    })

    const token = res.data as string

    await signInWithCustomToken(auth, token)
  } catch (error) {
    if (error instanceof Error && error.message === `USER_NOT_FOUND`) {
      isNewUser = true
      return {
        isNewUser,
      }
    } else {
      return handleError(error)
    }
  }

  return {
    isNewUser,
  }
}

const authViaMetamask = async (
  provider: EthersProvider,
  signer: EthersSigner
): Promise<void> => {
  try {
    const address = await signer.getAddress()

    if (!address) {
      throw new Error(`User address is null!`)
    }

    const nonce = await getUserNonce(address)
    const msg = await signData(nonce, provider, address)
    await verifyNonce(msg, address)
  } catch (error) {
    return handleError(error)
  }
}

export {
  authViaMetamask,
  authWeb3User,
  connectToWeb3Wallet,
  getUserNonce,
  verifyNonce,
  contracts,
  mintCollection,
}
