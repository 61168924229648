import { ethers } from 'ethers'
import { EthersSigner } from 'providers/Web3Provider'
// https://github.com/veritone/nft-marketplace-backend/blob/develop/pkg/blockchain/abi/MultiTokenFactory-abi.json

type Contract = {
  mint(
    address: string,
    supply: string,
    uri: string,
    erc20Token: string,
    senderAddress: string
  ): Promise<any>
  setApprovalForAll(operator: string, approved: boolean): Promise<any>
} & ethers.Contract

const multiTokenContract = (address: string, signer: EthersSigner) => {
  const abi: string[] = [
    'function mint(address, uint256, string, address, address) nonpayable returns ()',
    'function setApprovalForAll(address operator, bool approved) nonpayable returns ()',
  ]

  const contract = new ethers.Contract(address, abi, signer)

  return contract as Contract
}

export default multiTokenContract
