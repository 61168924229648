import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import BuyButton from 'feature/BuyButton'
import { Modes } from 'feature/EditionDetailsPage/hooks/useEditionDetailsPage'
import { useAppLinks } from 'hooks/routes'
import Edition from 'types/Edition'

type Props = {
  mode: Modes
} & Edition

const EditionsTable = ({ mode, ...edition }: Props) => {
  const { editionDetailsPage } = useAppLinks()
  if (mode !== Modes.MULTI) {
    return null
  }

  const { id, supply, price, sellerId, collectionId } = edition

  const entries = []

  for (let index = 0; index < supply; index++) {
    if (!collectionId) {
      throw new Error(`collectionId cannot be undefined!`)
    }
    const nftId = index + 1
    entries.push({
      id: nftId,
      price: price,
      detailsLink: () =>
        editionDetailsPage(sellerId, collectionId, id, nftId.toString()),
      buyNow: {
        sellerId,
        collectionId,
        id,
      },
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Edition',
      minWidth: 330,
      flex: 5,
      sortable: false,
    },
    {
      field: 'price',
      headerName: 'Current Listed Price',
      minWidth: 300,
      flex: 5,
      sortable: false,
    },
    {
      field: 'detailsLink',
      headerName: ``,
      minWidth: 150,
      flex: 1,
      sortable: false,
      align: `center`,
      renderCell: (params) => (
        <Button variant={`secondary`} onClick={() => params.value()}>
          View Details
        </Button>
      ),
    },
    {
      field: 'buyNow',
      headerName: ``,
      minWidth: 150,
      flex: 1,
      sortable: false,
      align: `center`,
      renderCell: (params) => (
        <BuyButton
          sellerId={params.value.sellerId}
          collectionId={params.value.collectionId}
          id={params.value.id}
        ></BuyButton>
      ),
    },
  ]

  return (
    <Box
      sx={{
        gridColumn: `1/-1`,
        minHeight: `300px`,
      }}
    >
      <DataGrid
        rows={entries}
        columns={columns}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
      ></DataGrid>
    </Box>
  )
}

export default EditionsTable
