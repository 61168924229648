import {
  Box,
  InputLabel,
  InputLabelProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { forwardRef } from 'react'

type Props = {
  id: string
  label: string
  error?: boolean
  labelProps?: InputLabelProps
} & TextFieldProps

const TextInput = forwardRef(
  ({ id, label, error, labelProps, ...textFieldProps }: Props, ref) => {
    return (
      <Box>
        <InputLabel htmlFor={id} error={error} {...labelProps}>
          {label}
        </InputLabel>
        <TextField
          id={id}
          error={error}
          helperText={` `}
          inputRef={ref}
          {...textFieldProps}
          fullWidth
        ></TextField>
      </Box>
    )
  }
)

export default TextInput
