import { ReactNode } from 'react'
import { RailModes } from '..'
import BuyRail from '../BuyRail'
import SellRail from '../SellRail'
import WalletRail from '../WalletRail'

const getPropsByRailType = (
  mode: RailModes
): {
  title: string
  component: ReactNode
} => {
  switch (mode) {
    case RailModes.NONE:
      return {
        title: ``,
        component: null,
      }
    case RailModes.BUY:
      return {
        title: `Purchase Item`,
        component: <BuyRail />,
      }
    case RailModes.SELL:
      return {
        title: `Sell Item`,
        component: <SellRail />,
      }
    case RailModes.WALLET:
      return {
        title: `My Wallets`,
        component: <WalletRail />,
      }
  }
}

export default getPropsByRailType
